import { NavLink } from 'react-router-dom';
import { Typography } from '@components/atoms';

interface ButtonTabSelectorProps {
  label: string;
  route?: string;
  isActive?: boolean;
  onClick?: () => void;
}

export const ButtonTabSelector = ({
  label,
  route,
  isActive,
  onClick,
}: ButtonTabSelectorProps) => {
  const getClassName = (isActive: boolean): string =>
    `w-full rounded-xl py-2 text-center ${
      isActive
        ? 'bg-primary-400 [&>*]:text-secondary-text-100'
        : 'bg-transparent [&>*]:text-secondary-text-400'
    }`;

  if (route) {
    return (
      <NavLink
        to={route}
        end
        className={({ isActive }) => getClassName(isActive)}
      >
        <Typography variant='span' className='font-bold'>
          {label}
        </Typography>
      </NavLink>
    );
  } else {
    return (
      <button className={getClassName(isActive!)} onClick={onClick}>
        <Typography variant='span' className='font-bold'>
          {label}
        </Typography>
      </button>
    );
  }
};
