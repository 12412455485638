import { useQuery } from '@tanstack/react-query';
import { getLessonStorage, trackGA4PageView } from '@data/utils';
import { Typography } from '@components/atoms';
import { LessonTemplate } from '@components/templates';
import { useLessons } from '@hooks/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import {
  LESSON_OPEN_PREVIOUS_STATE_STORAGE_KEY,
  LESSON_STATES_STR,
  PAGE_NAMES,
} from '@domain/constants';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { LessonSummary } from '@components/organisms';
import { MdLibraryAdd } from 'react-icons/md';
import AnimatedButton from 'src/ui/components/atoms/AnimatedButton/AnimatedButton';
import { FixedButtonWrapper } from 'src/ui/components/molecules';

interface LessonSummaryEmptyStateProps {
  description: string;
}

const EmptyState = ({ description }: LessonSummaryEmptyStateProps) => {
  return (
    <div className='flex w-full flex-col items-center gap-4 px-8 text-center'>
      <MdLibraryAdd size={32} className='flex-shrink-0 text-primary-300' />
      <Typography variant='span'>{description}</Typography>
    </div>
  );
};

export const LessonSummaryPage = () => {
  const { title, description, color, backgroundColor } = getLessonStorage();
  const { moduleId, classId, lessonId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(
    'pages/home/home/classes/modules/lessons/lessonSummaryPage'
  );

  const { getLessons, updateLessonStateMutation, getLessonDetails } =
    useLessons();
  const location = useLocation();
  const { isLoading, isPending } = useQuery({
    queryKey: ['getLessons'],
    queryFn: getLessons,
    retry: false,
  });
  const {
    data: lessonDetailsData,
    isLoading: isLoadingLessonDetails,
    isPending: isPendingLessonDetails,
  } = useQuery({
    queryKey: ['getLessonDetails', lessonId],
    queryFn: () => getLessonDetails(lessonId!),
    retry: false,
  });

  const openClassroom = useCallback(async () => {
    const lessonPreviousState = lessonDetailsData?.lesson.state;
    if (lessonId) {
      await updateLessonStateMutation.mutateAsync({
        lessonId: lessonId,
        state: LESSON_STATES_STR.start,
      });
      localStorage.setItem(
        LESSON_OPEN_PREVIOUS_STATE_STORAGE_KEY,
        lessonPreviousState
      );
    }

    navigate(
      PROTECTED_ROUTES_PATHS.activity
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', lessonId!)
    );
  }, [classId, moduleId, navigate, updateLessonStateMutation, lessonId]);

  const handleBackButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  };

  const isAnyLoading =
    isLoading || isPending || isLoadingLessonDetails || isPendingLessonDetails;

  const isContentEmpty = lessonDetailsData?.lesson.content.length === 0;

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.lessonSummary,
    });
  }, [location.pathname]);

  return (
    <LessonTemplate
      title={title}
      subtitle={description}
      style={{ backgroundColor, color }}
      isLoading={isAnyLoading}
      clickBackButton={handleBackButton}
      childrenClassName={`!mx-0 px-4 pb-20 mt-4 bg-secondary-surface-100 ${
        isContentEmpty && '!bg-secondary-surface-200 !mb-0'
      }`}
    >
      {!isContentEmpty ? (
        <>
          <LessonSummary content={lessonDetailsData?.lesson.content} />
          <FixedButtonWrapper>
            <AnimatedButton
              text={t('buttons.openClassroom')}
              style='primary'
              className=''
              type='button'
              onClick={openClassroom}
              loading={updateLessonStateMutation.isPending}
              disabled={updateLessonStateMutation.isPending}
            />
          </FixedButtonWrapper>
        </>
      ) : (
        <div className='grid flex-1 place-items-center'>
          <EmptyState description={t('emptyState.description')} />
        </div>
      )}
    </LessonTemplate>
  );
};
