import { useQuery } from '@tanstack/react-query';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handlePreviewMode, resetModalLesson } from '@store/slices';
import { getLessonStorage, NotificationsDispatcher } from '@data/utils';
import { LessonTemplate, SlideContent, Slide } from '@components/templates';
import { useLessons } from '@hooks/index';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { ToastNotificationType } from 'src/domain/constants';
import { useTranslation } from 'react-i18next';

export const ReviewActivityPage = () => {
  const { title, description, color, backgroundColor } = getLessonStorage();
  const { lessonId, moduleId, classId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLessonDetails } = useLessons();
  const { t } = useTranslation('pages/home/activity/review/reviewPage');

  const [step, setStep] = useState(0);

  const { data, isLoading, isPending, isError, isSuccess } = useQuery({
    queryKey: ['getLessonDetails', lessonId],
    queryFn: () => getLessonDetails(lessonId!),
    retry: false,
  });

  useLayoutEffect(() => {
    dispatch(handlePreviewMode({ isPreviewMode: true }));
    dispatch(resetModalLesson());
  }, [dispatch]);

  if (isError) {
    throw new Error();
  }

  const filteredContent = useMemo(() => {
    if (!data) return [];
    return data.lesson.content.filter(content => content.type === 'slide');
  }, [data]);

  const currentContent = filteredContent[step];
  const type = currentContent?.type;
  const subtype = currentContent?.subtype;

  const isDataLoading = isLoading || isPending;

  const navigateToLessons = useCallback(() => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  }, [classId, moduleId, navigate]);

  useEffect(() => {
    if (isSuccess) {
      if (filteredContent.length === 0) {
        // Notification may be displayed twice on Dev mode because of React Strict Mode
        NotificationsDispatcher({
          type: ToastNotificationType.info,
          title: t('toastNotifications.noContentFound.title'),
          message: t('toastNotifications.noContentFound.message'),
        });

        navigateToLessons();
        return;
      } else if (step === filteredContent.length) {
        navigateToLessons();
        return;
      }
    }
  }, [step, isSuccess, filteredContent.length, navigateToLessons, t]);

  return (
    <>
      <LessonTemplate
        title={title}
        subtitle={description}
        style={{ backgroundColor, color }}
        clickCloseButton={navigateToLessons}
        isLoading={isDataLoading}
        childrenClassName='!mx-0 !pt-0 bg-secondary-surface-100'
      >
        {data && step < filteredContent.length && (
          <Slide
            currentStep={step + 1}
            maxSteps={filteredContent.length}
            type={type}
            subtype={subtype}
            nextButtonText={'Next'}
            onClickNext={() => setStep(step + 1)}
            onShowResults={undefined}
            showResults={undefined}
          >
            <SlideContent
              slide={currentContent}
              channel={'si'}
              showResults={false}
              onClickNext={() => {}}
            />
          </Slide>
        )}
      </LessonTemplate>
    </>
  );
};
