import { ActivitySubtype, ActivityType } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { RichTextTypography, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';

interface RenderImageSlideProps {
  title: string;
  imageURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
  textContent?: string;
}

export const RenderImageSlide = ({
  title,
  imageURL,
  type,
  subtype,
  textContent,
}: RenderImageSlideProps) => {
  const { t } = useTranslation('components/templates/renderImageSlide');

  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <div className='grid h-full flex-1 grid-cols-1 gap-4 desktop:grid-cols-3'>
        <img
          src={imageURL!}
          alt={title}
          className='col-span-2 mb-8 h-56 w-full object-contain'
        />
        {textContent ? (
          <RichTextTypography description={textContent} />
        ) : (
          <Typography variant='span' className='!text-secondary-text-200'>
            {t('noComments')}
          </Typography>
        )}
      </div>
    </SlideContentWrapper>
  );
};
