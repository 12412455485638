import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

class LessonsApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getLessonDetails(lessonid: string) {
    return this.client.get(`/lessons/${lessonid}`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  updateLessonState(lessonid: string, state: string) {
    return this.client.patch(
      `/lessons/${lessonid}/state`,
      {
        state,
      },
      {
        headers: {
          Accept: '*/*',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  getLessonPoints(lessonid: string) {
    return this.client.get(`/lessons/${lessonid}/points`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  updateAttendance(lessonId: string) {
    return this.client.post(
      `/lessons/${lessonId}/attendance`,
      {},
      {
        headers: {
          Accept: '*/*',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  gradeEndingPrayerGame(
    lessonId: string,
    endingPrayerGameId: string,
    points: number
  ) {
    return this.client.post(
      `/lessons/${lessonId}/ending_prayer_game`,
      {
        ending_prayer_game_id: endingPrayerGameId,
        points: points,
      },
      {
        headers: {
          Accept: '*/*',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  getLeaderboard(lessonId: string) {
    return this.client.get(`/lessons/${lessonId}/leaderboard`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getContentResponses(contentId: string | number) {
    return this.client.get(`lessons/${contentId}/show_responses`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getShowContent(lessonId: string) {
    return this.client.get(`/lessons/${lessonId}/show_content`, {
      headers: {
        Accept: '*/*',
      },
    });
  }
}

export { LessonsApi };
