import { Carousel, Typography } from '@components/atoms';
import { ReminderCard, ReminderCardProps } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { MdSupervisorAccount } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface ReminderListProps {
  reminder: ReminderCardProps;
}

export const ReminderList = ({ reminder }: ReminderListProps) => {
  const parents = useSelector(UserSelectors.getParents);

  const { t } = useTranslation('components/organisms/reminderList');

  const parentReminder =
    parents.length === 0
      ? {
          title: t('parentsTitle'),
          subtitle: t('parentsDescription'),
          icon: (
            <MdSupervisorAccount
              size={24}
              className='flex-shrink-0 !text-primary-400'
            />
          ),
        }
      : undefined;

  const reminders = [reminder, parentReminder].filter(reminder => reminder);

  if (reminder || !parents.length)
    return (
      <article className='mt-4 flex w-full flex-col gap-4'>
        <Typography variant='h1'>{t('title')}</Typography>
        <Carousel
          data={reminders}
          CardComponent={ReminderCard}
          idLabel='reminder'
        />
      </article>
    );
};
