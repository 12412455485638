import { useState } from 'react';
import {
  SignupClassCodePage,
  SignupUserPage,
  SignupGuardianPage,
  SignupAvatarPage,
} from './index';
import { useNavigate } from 'react-router-dom';
import {
  PROTECTED_ROUTES_PATHS,
  PUBLIC_ROUTES_PATHS,
} from '@navigation/routes/RoutesPaths';
import { Congratulations } from '@components/templates';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { WELCOME_BONUS } from '@domain/constants';

export interface SignupProps {
  handleNextStep: () => void;
  handlePrevStep: () => void;
}

export const SignupController = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const userFullName = useSelector(UserSelectors.getFullName);

  const { t } = useTranslation(['pages/auth/signup/signupCongratulationsPage']);

  const navigate = useNavigate();

  const handleNextStep = (): void => {
    if (currentStep < SIGNUP_STEPS.length - 1) {
      setCurrentStep(currentStep + 1);
      return;
    }
    navigate(PROTECTED_ROUTES_PATHS.home);
  };

  const handlePrevStep = (): void => {
    if (currentStep <= 0) {
      navigate(PUBLIC_ROUTES_PATHS.login);
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const SIGNUP_STEPS = [
    {
      step: 1,
      component: (
        <SignupClassCodePage
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 2,
      component: (
        <SignupUserPage
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 3,
      component: (
        <SignupGuardianPage
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 4,
      component: (
        <SignupAvatarPage
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      ),
    },
    {
      step: 5,
      component: (
        <Congratulations
          title={t('title')}
          tacoPoints={Number(sessionStorage.getItem(WELCOME_BONUS))}
          description={t('descriptionOne')}
          studentName={` ${userFullName} `}
          descriptionTwo={t('descriptionTwo')}
          onClick={handleNextStep}
        />
      ),
    },
  ];

  return SIGNUP_STEPS[currentStep].component;
};
