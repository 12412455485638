import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Button,
  CountdownBar,
  AnimatedScore,
} from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { MdCheck, MdClear, MdClose } from 'react-icons/md';
import { motion } from 'framer-motion';
import { ModalConfirmation } from '@components/organisms';
import { gameQuitLabel, PrayerGameAnalytics } from '@domain/constants';
import { trackGA4Events } from '@data/utils';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface DailyPrayerGameProps {
  gameId: string;
  question: string;
  options: string[];
  onSelectAnswer: (answer: string, points: number) => void;
  timeLeft: number;
  initialTime: number;
  score: number;
  onCloseClick: () => void;
  imageUrl: string;
  isTransitioning: boolean;
  minPoints: number;
  maxPoints: number;
  correctAnswer: string;
  onPause: () => void;
  onResume: () => void;
  isHalfPoints: boolean;
}

export const DailyPrayerGame: React.FC<DailyPrayerGameProps> = ({
  gameId,
  question,
  options,
  onSelectAnswer,
  timeLeft,
  initialTime,
  score,
  onCloseClick,
  imageUrl,
  isTransitioning,
  minPoints,
  maxPoints,
  correctAnswer,
  onPause,
  onResume,
  isHalfPoints,
}) => {
  const { t } = useTranslation('components/templates/dailyPrayerGame');
  const [showContent, setShowContent] = useState(false);
  const [lastPoints, setLastPoints] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  const userId = useSelector(UserSelectors.getId);

  useEffect(() => {
    if (!isTransitioning) {
      const timer = setTimeout(() => {
        setShowContent(true);
        setLastPoints(0);
        setSelectedAnswer(null);
        setAnswerSubmitted(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setShowContent(false);
    }
  }, [isTransitioning, question]);

  const renderQuestionWithBlanks = (text: string) => {
    const parts = text.split(/(#blank[.,!?;:]?)/);
    return parts.map((part, index) => {
      if (part.startsWith('#blank')) {
        return (
          <span
            key={index}
            className='sm:text-base inline-flex items-center justify-center rounded-xl border-2 border-primary-400 bg-gray-200 text-sm'
            style={{
              minWidth: '4rem',
              height: '2rem',
              margin: '0 2px',
              display: 'inline-flex',
              verticalAlign: 'middle',
            }}
          />
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const calculatePoints = (
    timeLeft: number,
    initialTime: number,
    minPoints: number,
    maxPoints: number,
    isHalfPoints: boolean
  ): number => {
    if (timeLeft <= 0) return 0;
    const timePercentage = (timeLeft / initialTime) * 100;
    let points = 0;
    if (timePercentage > 75) points = maxPoints;
    else if (timePercentage > 50) points = Math.ceil(maxPoints * 0.75);
    else if (timePercentage > 25) points = Math.ceil(maxPoints * 0.5);
    else points = minPoints;

    if (isHalfPoints && points !== maxPoints / 2 && points !== minPoints / 2) {
      points = Math.floor(points / 2);
    }

    return points;
  };

  const handleSelectAnswer = useCallback(
    (answer: string) => {
      setSelectedAnswer(answer);
      setAnswerSubmitted(true);
      const points = calculatePoints(
        timeLeft,
        initialTime,
        minPoints,
        maxPoints,
        isHalfPoints
      );
      const finalPoints = answer === correctAnswer ? points : 0;

      if (answer === correctAnswer) {
        setLastPoints(finalPoints);
      } else {
        setLastPoints(0);
      }

      // Delay the onSelectAnswer call to allow time for feedback
      setTimeout(() => {
        onSelectAnswer(answer, finalPoints);
      }, 2000); // 2 seconds delay, adjust as needed
    },
    [
      timeLeft,
      initialTime,
      minPoints,
      maxPoints,
      correctAnswer,
      onSelectAnswer,
      isHalfPoints,
    ]
  );

  const getButtonClassName = (option: string) => {
    if (!answerSubmitted) return '';
    if (option === correctAnswer) {
      return 'border-2 border-secondary-semantic-success-400 !font-bold';
    }
    if (option === selectedAnswer && option !== correctAnswer) {
      return 'border-2 border-secondary-semantic-error-400 !font-bold';
    }
    return '';
  };

  const handleCloseButtonClick = useCallback(() => {
    setShowExitConfirmation(true);
    onPause(); // Pause the game when showing the confirmation modal
  }, [onPause]);

  const handleConfirmExit = useCallback(() => {
    setShowExitConfirmation(false);

    trackGA4Events(
      PrayerGameAnalytics.categories.gameQuit,
      PrayerGameAnalytics.actions.prayerGame,
      gameQuitLabel(
        new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        userId.toString(),
        gameId.toString()
      )
    );

    onCloseClick();
  }, [onCloseClick, gameId, userId]);

  const handleCancelExit = useCallback(() => {
    setShowExitConfirmation(false);
    onResume(); // Resume the game when canceling the exit
  }, [onResume]);

  if (!question || !options || options.length === 0) {
    return null;
  }

  return (
    <div className='flex h-full w-full flex-col bg-secondary-surface-100'>
      <div className='flex flex-col space-y-4 px-4 py-2'>
        <div className='mx-auto w-full max-w-[358px] pt-4'>
          <div className='mb-4 flex items-center justify-between'>
            <button
              className='text-xl text-purple-600'
              onClick={handleCloseButtonClick}
            >
              <MdClose size={24} />
            </button>
            <div className='font-bold text-purple-600'>
              {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}
              {timeLeft % 60}
            </div>
            <AnimatedScore
              score={score}
              lastPoints={lastPoints}
              isHalfPoints={false}
            />
          </div>

          <CountdownBar
            initialTime={initialTime}
            currentTime={timeLeft}
            containerStyle='mb-4'
          />

          <div className='w-full overflow-hidden rounded-lg'>
            <img
              src={imageUrl}
              alt='Prayer'
              className='h-full w-full object-cover'
              style={{ maxHeight: '228px' }}
            />
          </div>

          <div
            className={`mt-4 w-full transition-opacity duration-500 ${
              showContent ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div className='w-full rounded-lg bg-secondary-surface-200 p-4 shadow-general'>
              <Typography variant='p' className='mb-4 text-center text-sm'>
                {renderQuestionWithBlanks(question)}
              </Typography>

              <div className='space-y-2'>
                {options.map((option, index) => (
                  <motion.div
                    key={`${question}-${option}`}
                    initial={{
                      x: index % 2 === 0 ? '-100vw' : '100vw',
                      opacity: 0,
                    }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                      type: 'spring',
                      stiffness: 100,
                      damping: 15,
                      mass: 0.5,
                      delay: index * 0.2,
                    }}
                    className='w-full'
                  >
                    <Button
                      onClick={() => handleSelectAnswer(option)}
                      className={`w-full min-w-[250px] rounded-lg bg-purple-100 px-4 py-2 text-sm text-purple-800 ${getButtonClassName(
                        option
                      )}`}
                      style='poll'
                      text={option}
                      disabled={
                        !showContent || isTransitioning || answerSubmitted
                      }
                      iconRight={
                        answerSubmitted &&
                        (option === correctAnswer ? (
                          <MdCheck
                            size={24}
                            className='flex-shrink-0 text-secondary-semantic-success-400'
                          />
                        ) : option === selectedAnswer ? (
                          <MdClear
                            size={24}
                            className='flex-shrink-0 text-secondary-semantic-error-400'
                          />
                        ) : null)
                      }
                    />
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalConfirmation
        open={showExitConfirmation}
        onClose={handleCancelExit}
        title={t('exitConfirmation.title')}
        text={t('exitConfirmation.text')}
        handleConfirm={handleConfirmExit}
        handleCancel={handleCancelExit}
        handleConfirmText={t('exitConfirmation.confirm')}
      />
    </div>
  );
};
