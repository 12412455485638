import { ReactNode } from 'react';

interface BorderContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  containerStyles?: string;
}

export const BorderContainer = ({
  children,
  containerStyles,
  ...rest
}: BorderContainerProps) => {
  return (
    <div className={`w-full rounded-xl p-2 ${containerStyles}`} {...rest}>
      {children}
    </div>
  );
};
