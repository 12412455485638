import {
  Divider,
  Input,
  Select,
  Typography,
  Button,
  Loader,
} from '@components/atoms';
import { ModalConfirmation } from '@components/organisms';
import { InputType } from '@domain/constants';
import { emailRules, nameRules, phoneNumberRules } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdChevronLeft, MdDelete } from 'react-icons/md';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addParent, deleteParent, UserSelectors } from '@store/slices';
import { useProfile } from '@hooks/index';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

type Inputs = {
  parents: Parent[];
};

type Parent = {
  relationship: number | string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

export const ParentsForm = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [originalParents, setOriginalParents] = useState<
    Record<string, number | string>[]
  >([]);

  const dispatch = useDispatch();
  const { getUser, navigateToProfileDetails, editParentsMutation } =
    useProfile();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['userDetails'],
    queryFn: getUser,
  });

  const { t } = useTranslation('pages/auth/signup/signupGuardianPage');

  const handleSubmitForm = async (data: Inputs): Promise<void> => {
    await editParentsMutation.mutateAsync({
      data,
      oldData: originalParents,
    });
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      parents: data?.parents,
    },
    mode: 'onChange',
  });

  const { parents } = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parents',
  });

  const handleAddParent = useCallback(() => {
    append({
      relationship: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    });
    dispatch(addParent());
  }, [append, dispatch]);

  const handleDeleteParent = (index: number): void => {
    remove(index);
    dispatch(deleteParent(index));
  };

  useEffect(() => {
    setOriginalParents(data?.parents);
  }, [data?.parents]);

  useEffect(() => {
    if ((!isPending || !isLoading) && data) {
      if (data?.parents.length > 0) {
        setValue('parents', data.parents);
        return;
      }

      if (data?.parents.length === 0) {
        setValue('parents', []);
        handleAddParent();
        return;
      }
    }
  }, [data, setValue, isLoading, isPending, handleAddParent]);

  const studentEmail = useSelector(UserSelectors.getEmail);

  const validateEmailUniqueness = (
    email: string,
    index: number,
    studentEmail: string
  ) => {
    const guardians = getValues('parents');

    if (!email || !studentEmail) return;

    const lowercaseEmail = email.toLowerCase();

    if (lowercaseEmail === studentEmail.toLowerCase())
      return t('errors.emailAlreadyUsed');

    return (
      !guardians.some(
        (guardian, idx) =>
          idx !== index && guardian.email.toLowerCase() === lowercaseEmail
      ) || t('errors.emailAlreadyUsed')
    );
  };

  return (
    <div>
      {isLoading || isPending ? (
        <Loader />
      ) : (
        <>
          <MdChevronLeft
            size={24}
            onClick={navigateToProfileDetails}
            className='mb-6 flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-500'
          />
          <Typography variant='h1'>{t('title')}</Typography>
          <Typography variant='span'>{t('subtitleOne')}</Typography>
          <Typography variant='p'>{t('subtitleTwo')}</Typography>
          <form
            className='mt-4 w-full space-y-2'
            onSubmit={handleSubmit(handleSubmitForm)}
            id='submit-parent-form'
          >
            {fields.map((_, index) => (
              <div key={index}>
                {index !== 0 && (
                  <div className='mb-6 flex flex-col justify-between gap-4'>
                    <Divider />
                    <div className='flex justify-between'>
                      <Typography variant='h2'>
                        {t('aditionalParent')}
                      </Typography>
                      <MdDelete
                        size={24}
                        onClick={() => setDeleteModalOpen(true)}
                        className='text-secondary-semantic-error-400 hover:cursor-pointer'
                      />
                    </div>
                  </div>
                )}
                <Select
                  control={control}
                  name={`parents.${index}.relationship`}
                  options={[
                    { value: 'mother', label: 'Mother' },
                    { value: 'father', label: 'Father' },
                    { value: 'grandparent', label: 'Grandparent' },
                    { value: 'legal_guardian', label: 'Legal Guardian' },
                    { value: 'other', label: 'Other' },
                  ]}
                  rules={{
                    required: {
                      value: true,
                      message: t('errors.relationship'),
                    },
                  }}
                  textLabel={t('inputs.relationship')}
                />
                <Input
                  control={control}
                  name={`parents.${index}.firstName`}
                  label={t('inputs.name')}
                  rules={nameRules(
                    t('errors.name'),
                    t('inputs.errors.invalid', {
                      inputName: 'first name',
                      ns: 'common',
                    })
                  )}
                />
                <Input
                  control={control}
                  name={`parents.${index}.lastName`}
                  label={t('inputs.lastName')}
                  rules={nameRules(
                    t('errors.lastName'),
                    t('inputs.errors.invalid', {
                      inputName: 'last name',
                      ns: 'common',
                    })
                  )}
                />
                <Input
                  control={control}
                  name={`parents.${index}.phoneNumber`}
                  label={t('inputs.phoneNumber')}
                  type={InputType.phoneNumber}
                  rules={phoneNumberRules(
                    t('errors.phoneNumber'),
                    t('inputs.errors.invalid', {
                      inputName: 'phone number',
                      ns: 'common',
                    })
                  )}
                  inputMode='numeric'
                />
                <Input
                  control={control}
                  name={`parents.${index}.email`}
                  label={t('inputs.email')}
                  rules={{
                    ...emailRules(
                      t('errors.email'),
                      t('inputs.errors.invalid', {
                        inputName: 'email',
                        ns: 'common',
                      })
                    ),
                    validate: (value: string) => {
                      return validateEmailUniqueness(
                        value,
                        index,
                        studentEmail
                      );
                    },
                  }}
                  inputMode='email'
                />

                <ModalConfirmation
                  handleCancel={() => setDeleteModalOpen(false)}
                  handleConfirm={() => handleDeleteParent(index)}
                  open={deleteModalOpen}
                  text={t('modals.delete.text')}
                  title={t('modals.delete.title')}
                />
              </div>
            ))}

            {parents.length <= 1 && (
              <Button
                iconLeft={<MdAdd size={24} className='text-primary-400' />}
                type='button'
                style='link'
                onClick={handleAddParent}
                text={t('buttons.addParent')}
              />
            )}
          </form>

          <Button
            text={t('button.continue', { ns: 'common' })}
            style='primary'
            disabled={!isValid || editParentsMutation.isPending}
            loading={editParentsMutation.isPending}
            form='submit-parent-form'
            type='submit'
          />
        </>
      )}
    </div>
  );
};
