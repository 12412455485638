import { ModalSheet } from '@components/molecules';
import { useState } from 'react';
import {
  BorderContainer,
  Button,
  Loader,
  Textarea,
  Typography,
} from '@components/atoms';
import { useForm } from 'react-hook-form';
import { useRetos } from '@hooks/index';
import { NotificationsDispatcher, retoRules } from '@data/utils';
import {
  ChallengeType,
  RETO_STATES_STR,
  ToastNotificationType,
} from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface ModalSheetRetoAnswerProps {
  answer?: string;
  retoType: ChallengeType;
  state?: RETO_STATES_STR;
  refetch: () => void;
}

interface RenderButtonProps {
  isSubmitting: boolean;
  isValid: boolean;
  state: RETO_STATES_STR;
  t: TFunction<'components/organisms/modalSheetRetoAnswer'>;
}

type Inputs = {
  answer: string;
};

const renderButtons = ({
  isSubmitting,
  isValid,
  state,
  t,
}: RenderButtonProps) => {
  if (isSubmitting)
    return (
      <div className='mb-6 flex h-12 items-center justify-center'>
        <Loader />
      </div>
    );

  if (state === RETO_STATES_STR.submitted) {
    return (
      <BorderContainer containerStyles='bg-secondary-surface-100 mb-6 border border-primary-200 flex flex-col items-center px-4 py-2 text-center gap-2'>
        <Typography variant='span' className='font-bold !text-primary-400'>
          {t('states.submitted.title', {
            ns: 'components/organisms/modalSheetRetoAnswer',
          })}
        </Typography>
        <Typography variant='span' className='!text-primary-300'>
          {t('states.submitted.message', {
            ns: 'components/organisms/modalSheetRetoAnswer',
          })}
        </Typography>
      </BorderContainer>
    );
  }

  return (
    <div className='mb-6 flex w-full gap-2 [&>*]:flex-1'>
      <Button
        form='edit-answer'
        type='submit'
        style='secondary'
        disabled={isSubmitting || !isValid}
        text={t('buttons.saveAsDraft', {
          ns: 'components/organisms/modalSheetRetoAnswer',
        })}
        name='action'
        value={RETO_STATES_STR.draft}
      />
      <Button
        form='edit-answer'
        type='submit'
        style='primary'
        disabled={isSubmitting || !isValid}
        text={t('buttons.submitAnswer', {
          ns: 'components/organisms/modalSheetRetoAnswer',
        })}
        name='action'
        value={RETO_STATES_STR.submitted}
      />
    </div>
  );
};

export const ModalSheetRetoAnswer = ({
  answer,
  retoType,
  state,
  refetch,
}: ModalSheetRetoAnswerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation([
    'common',
    'components/organisms/modalSheetRetoAnswer',
  ]);

  const { submitRetoResponseMutation } = useRetos();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAnswerSubmit = async (inputs: Inputs, event: any) => {
    const state = event.nativeEvent.submitter.value;
    try {
      await submitRetoResponseMutation.mutateAsync({
        retoResponse: { answer: inputs.answer, state },
        retoType,
      });

      const notificationState =
        state === RETO_STATES_STR.draft ? 'successDraft' : 'successSubmitted';

      refetch();

      NotificationsDispatcher({
        type: ToastNotificationType.success,
        title: t(`toastNotifications.${notificationState}.title`, {
          ns: 'components/organisms/modalSheetRetoAnswer',
        }),
        message: t(`toastNotifications.${notificationState}.message`, {
          ns: 'components/organisms/modalSheetRetoAnswer',
        }),
      });

      handleClose();
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      answer: answer || '',
    },
    mode: 'onChange',
  });

  return (
    <div>
      <Button
        type='button'
        style='link'
        className='mt-6 !w-full'
        text={t('buttons.showAnswer', {
          ns: 'components/organisms/modalSheetRetoAnswer',
        })}
        onClick={() => setIsOpen(true)}
      />
      <ModalSheet isOpen={isOpen} onClose={handleClose}>
        <form
          onSubmit={handleSubmit((data, event) =>
            handleAnswerSubmit(data, event)
          )}
          className='my-6 space-y-6'
          id='edit-answer'
        >
          <Textarea
            control={control}
            name='answer'
            placeholder='Your answer...'
            className={
              'h-52 max-h-52 min-h-12 resize-none overflow-auto leading-[18px]'
            }
            autoCorrect='off'
            rules={retoRules()}
          />
        </form>
        {renderButtons({
          isSubmitting,
          isValid,
          t,
          state,
        })}
      </ModalSheet>
    </div>
  );
};
