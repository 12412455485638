import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HomeTemplate } from '@components/templates';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { ModalSheetDailyPrayer } from '@components/templates/ModalSheetDailyPrayer/ModalSheetDailyPrayer';
import { DailyPrayerGame } from '@components/templates/DailyPrayerGame/DailyPrayerGame';
import { Button } from '@components/atoms';
import {
  getDailyPrayerGameStorage,
  NotificationsDispatcher,
  trackGA4Events,
} from '@data/utils';
import { usePrayerGame } from '@hooks/index';
import { CompleteDailyPrayer } from '@components/molecules/CompleteDailyPrayer/CompleteDailyPrayer';
import { ModalSheetPointsGlobal } from '@components/templates/ModalSheetPointsGlobal/ModalSheetPointsGlobal';
import { openPointsModal, openInfoModal, UserSelectors } from '@store/slices';
import { useTranslation } from 'react-i18next';
import {
  ToastNotificationType,
  DAILY_PRAYER_MULTIPLIER,
  DAILY_PRAYER_WEEKLY_STREAK_BONUS,
  PrayerGameAnalytics,
  gameStartedLabel,
} from '@domain/constants';
import { useHome } from '@hooks/useHome';
import { useAchievements } from '@hooks/useAchievements';
import { achievedBadgesAdapter } from '@data/adapters';

const LAST_PLAYED_KEY = 'lastPlayedDailyPrayerGame';

export const PrayerGamePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [dailyPrayerGame, setDailyPrayerGame] = useState<any>(null);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [totalPoints, setTotalPoints] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { submitDailyPrayerGame } = usePrayerGame();
  const toastShownRef = useRef(false);
  const { t, ready } = useTranslation(['pages/home/prayerGame/prayerGamePage']);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const { getHome } = useHome();
  const { getAchievedBadges } = useAchievements();

  const userAnswersRef = useRef<{ [key: number]: string }>({});
  const totalPointsRef = useRef(0);

  const [currentSentence, setCurrentSentence] = useState<any>(null);
  const [scoreMultiplier, setScoreMultiplier] = useState(1);
  const [isHalfPoints, setIsHalfPoints] = useState(false);

  const [finalPoints, setFinalPoints] = useState(0);
  const [visualFinalPoints, setVisualFinalPoints] = useState(0);

  const userId = useSelector(UserSelectors.getId);

  useEffect(() => {
    if (ready) {
      setTranslationsLoaded(true);
    }
  }, [ready]);

  useEffect(() => {
    if (!translationsLoaded) return;

    const storedData = getDailyPrayerGameStorage();
    if (storedData && storedData.sentences && storedData.sentences.length > 0) {
      setDailyPrayerGame(storedData);
      setTimeLeft(storedData.timer);
      setCurrentSentence(storedData.sentences[0]);

      // Check if the game was played today
      const lastPlayed = localStorage.getItem(LAST_PLAYED_KEY);
      const today = new Date();
      const lastPlayedDate = lastPlayed ? new Date(lastPlayed) : null;

      if (
        lastPlayedDate &&
        lastPlayedDate.getDate() === today.getDate() &&
        lastPlayedDate.getMonth() === today.getMonth() &&
        lastPlayedDate.getFullYear() === today.getFullYear()
      ) {
        setScoreMultiplier(0.5);
        setIsHalfPoints(true);
      } else {
        setScoreMultiplier(1);
        setIsHalfPoints(false);
      }
    } else if (!toastShownRef.current) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.error.title'),
        message: t('toastNotifications.error.message'),
      });
      toastShownRef.current = true;
      navigate(PROTECTED_ROUTES_PATHS.home);
    }
  }, [navigate, t, translationsLoaded]);

  const handleGameOver = useCallback(() => {
    setIsGameOver(true);
    setGameStarted(false);

    const allCorrectAnswers = dailyPrayerGame.sentences.every(
      (sentence: any, index: number) =>
        userAnswersRef.current[index] === sentence.correct_answer
    );

    let calculatedFinalPoints = totalPointsRef.current;
    let visualFinalPoints = calculatedFinalPoints;

    // Apply the all-correct bonus using the multiplier from session storage
    const multiplier = Number(
      sessionStorage.getItem(DAILY_PRAYER_MULTIPLIER) || '1'
    );
    if (allCorrectAnswers) {
      visualFinalPoints *= multiplier;
    }

    // Format answers for submission
    const formattedAnswers = dailyPrayerGame.sentences.reduce(
      (acc, sentence, index) => {
        acc[index + 1] = userAnswersRef.current[index] || '';
        return acc;
      },
      {} as { [key: number]: string }
    );

    const answerString = Object.entries(formattedAnswers)
      .map(([key, value]) => `${key} : ${value}`)
      .join(', ');

    submitDailyPrayerGame.mutate(
      {
        id: dailyPrayerGame.public_uid,
        points: calculatedFinalPoints,
        allCorrectAnswers,
        answers: `{${answerString}}`,
      },
      {
        onSuccess: async response => {
          const { achievement } = response;

          // Handle weekly streak bonus
          const weeklyStreakBonus = achievement.streak
            ? Number(
                sessionStorage.getItem(DAILY_PRAYER_WEEKLY_STREAK_BONUS) || 0
              )
            : 0;

          // Add weekly streak bonus to both calculatedFinalPoints and visualFinalPoints
          calculatedFinalPoints += weeklyStreakBonus;
          visualFinalPoints += weeklyStreakBonus;

          setFinalPoints(calculatedFinalPoints);
          setVisualFinalPoints(visualFinalPoints);

          // Resubmit the updated points to the database
          if (weeklyStreakBonus > 0) {
            submitDailyPrayerGame.mutate({
              id: dailyPrayerGame.public_uid,
              points: calculatedFinalPoints,
              allCorrectAnswers,
              answers: `{${answerString}}`,
            });
          }

          // Handle achievements
          const adaptedAchievements = achievedBadgesAdapter({ achievement });

          if (adaptedAchievements.dailyPrayerStreak) {
            const {
              id: badgeId,
              title,
              description,
              imageURL,
              level,
              levels,
            } = adaptedAchievements.dailyPrayerStreak;

            dispatch(
              openInfoModal({
                id: badgeId,
                title: `Level ${levels - level.level + 1}`,
                subtitle: title,
                description: description,
                image: imageURL,
                isBadge: true,
                backgroundColor: 'blue',
                level: level.level,
                levels: levels,
                isOpen: true,
              })
            );
          }

          try {
            await getAchievedBadges();
          } catch (error) {
            console.error('Error fetching achieved badges:', error);
          }

          getHome().catch(error => {
            console.error(
              'Error fetching home data after game completion:',
              error
            );
          });
        },
      }
    );
  }, [
    dailyPrayerGame,
    submitDailyPrayerGame,
    getHome,
    getAchievedBadges,
    dispatch,
  ]);

  const handleNextSentence = useCallback(() => {
    if (!dailyPrayerGame || !dailyPrayerGame.sentences) return;

    setIsTransitioning(true);
    setTimeout(() => {
      if (currentSentenceIndex < dailyPrayerGame.sentences.length - 1) {
        const nextIndex = currentSentenceIndex + 1;
        setCurrentSentenceIndex(nextIndex);
        setCurrentSentence(dailyPrayerGame.sentences[nextIndex]);
        setTimeLeft(dailyPrayerGame.timer);
        setTimeout(() => {
          setIsTransitioning(false);
        }, 500);
      } else {
        handleGameOver();
      }
    }, 1000);
  }, [currentSentenceIndex, dailyPrayerGame, handleGameOver]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      gameStarted &&
      timeLeft > 0 &&
      !isTransitioning &&
      !isGameOver &&
      !isPaused
    ) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNextSentence();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else if (timeLeft === 0 && gameStarted && !isGameOver) {
      handleNextSentence();
    }
    return () => clearInterval(timer);
  }, [
    gameStarted,
    timeLeft,
    isTransitioning,
    isGameOver,
    isPaused,
    handleNextSentence,
  ]);

  const handleSelectAnswer = useCallback(
    (answer: string, points: number) => {
      setUserAnswers(prev => ({
        ...prev,
        [currentSentenceIndex]: answer,
      }));
      userAnswersRef.current[currentSentenceIndex] = answer; // Update the ref
      setScore(prevScore => {
        const newScore = prevScore + points;
        return newScore;
      });
      setTotalPoints(prevTotal => {
        const newTotal = prevTotal + points;
        totalPointsRef.current = newTotal;
        return newTotal;
      });
      handleNextSentence();
    },
    [currentSentenceIndex, handleNextSentence]
  );

  const handleStartGame = useCallback(() => {
    setIsModalOpen(false);
    setGameStarted(true);
    setCurrentSentenceIndex(0);
    setTimeLeft(dailyPrayerGame.timer);
    setScore(0);
    setIsGameOver(false);
    setIsTransitioning(false);

    trackGA4Events(
      PrayerGameAnalytics.categories.gameStart,
      PrayerGameAnalytics.actions.prayerGame,
      gameStartedLabel(
        new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        userId.toString(),
        dailyPrayerGame.public_uid.toString()
      )
    );
  }, [dailyPrayerGame]);

  const handlePauseGame = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleResumeGame = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handleCloseGame = useCallback(() => {
    if (isGameOver) {
      dispatch(
        openPointsModal({
          id: 'dailyPrayerGame',
          title: 'Daily Prayer Game Completed!',
          pointsEarned: Math.round(visualFinalPoints),
        })
      );
      setTimeout(() => {
        navigate(PROTECTED_ROUTES_PATHS.home);
      }, 500);
    } else {
      navigate(PROTECTED_ROUTES_PATHS.home);
    }
  }, [isGameOver, dispatch, visualFinalPoints, navigate]);

  const shuffledOptions = useMemo(() => {
    if (!currentSentence) return [];
    return [
      currentSentence.correct_answer,
      currentSentence.incorrect_answer_1,
      currentSentence.incorrect_answer_2,
      currentSentence.incorrect_answer_3,
    ].sort(() => Math.random() - 0.5);
  }, [currentSentence]);

  const completePrayerText = useMemo(() => {
    if (!dailyPrayerGame || !dailyPrayerGame.sentences) return '';
    return dailyPrayerGame.sentences.map((s: any) => s.sentence).join(' ');
  }, [dailyPrayerGame]);

  if (!translationsLoaded) {
    return null;
  }

  if (!dailyPrayerGame || !dailyPrayerGame.sentences) {
    return null;
  }

  return (
    <div className='relative h-screen w-full'>
      <HomeTemplate>
        {!gameStarted && !isGameOver && (
          <ModalSheetDailyPrayer
            isOpen={isModalOpen}
            onClose={handleCloseGame}
            onStartGame={handleStartGame}
            isHalfPoints={isHalfPoints}
          />
        )}
        {gameStarted && !isGameOver && currentSentence && (
          <div className='absolute inset-0 z-50'>
            <DailyPrayerGame
              gameId={dailyPrayerGame.public_uid}
              question={currentSentence.sentence}
              options={shuffledOptions}
              onSelectAnswer={handleSelectAnswer}
              timeLeft={timeLeft}
              initialTime={dailyPrayerGame.timer}
              score={score}
              onCloseClick={handleCloseGame}
              imageUrl={dailyPrayerGame.image_url}
              isTransitioning={isTransitioning}
              minPoints={dailyPrayerGame.min_points}
              maxPoints={dailyPrayerGame.max_points}
              correctAnswer={currentSentence.correct_answer}
              onPause={handlePauseGame}
              onResume={handleResumeGame}
              isHalfPoints={isHalfPoints}
            />
          </div>
        )}
        {isGameOver && (
          <div className='absolute inset-0 z-50 flex items-center justify-center bg-secondary-surface-100'>
            <div className='w-full max-w-md px-4'>
              <CompleteDailyPrayer
                text={completePrayerText}
                correctAnswers={dailyPrayerGame.sentences.map(
                  (s: any) => s.correct_answer
                )}
                imageUrl={dailyPrayerGame.image_url}
                title={dailyPrayerGame.title}
              />
              <div className='mt-8'>
                <Button
                  onClick={handleCloseGame}
                  text='Finish'
                  style='primary'
                  className='w-full'
                />
              </div>
            </div>
          </div>
        )}
      </HomeTemplate>
      <ModalSheetPointsGlobal />
    </div>
  );
};
