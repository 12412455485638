import { ShopItem, ShopItemProps } from '@components/molecules';
import { MdShoppingBag } from 'react-icons/md';
import { Typography } from '@components/atoms';
import { motion } from 'framer-motion';

interface ShopItemListProps extends ShopEmptyStateProps {
  items: ShopItemProps[];
  studentPoints?: number | string | null;
  onClick?: () => void;
  hidePrice?: boolean;
  hideEmptyState?: boolean;
  className?: string;
  selectedAvatar?: number | null;
  isShopPage?: boolean;
}

interface ShopEmptyStateProps {
  emptyStateText?: string;
}

const EmptyState = ({ emptyStateText }: ShopEmptyStateProps) => {
  return (
    <div className='flex w-full flex-col items-center  px-8 text-center'>
      <MdShoppingBag size={40} className='flex-shrink-0 text-primary-300' />
      <Typography variant='span'>{emptyStateText!}</Typography>
    </div>
  );
};

export const ShopItemList = ({
  items,
  studentPoints,
  emptyStateText,
  onClick,
  hidePrice = false,
  hideEmptyState = false,
  className,
  selectedAvatar,
  isShopPage = false,
}: ShopItemListProps) => {
  return items.length === 0 && !hideEmptyState ? (
    <EmptyState emptyStateText={emptyStateText} />
  ) : (
    <div className={`mt-4 grid grid-cols-3 gap-y-4 ${className}`}>
      {items.map(
        ({
          id,
          title,
          imageURL,
          requiredPoints,
          purchasedByStudent,
          link,
          type,
          description,
        }) => (
          <motion.div
            key={id}
            initial={{ opacity: 0 }} // Start below the viewport and hidden
            animate={{ opacity: 1 }} // Move up and become visible
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <ShopItem
              key={id}
              id={id}
              name={title}
              avatarImage={type === 'avatar' ? imageURL : null}
              collectibleImage={type === 'collectible' ? imageURL : null}
              price={requiredPoints}
              nameClassname={isShopPage ? '!line-clamp-1 mx-2' : ''}
              studentPoints={studentPoints}
              isPurchased={purchasedByStudent}
              onClick={onClick}
              link={link}
              description={description}
              type={type}
              hidePrice={hidePrice}
              selectedAvatar={selectedAvatar || null}
            />
          </motion.div>
        )
      )}
    </div>
  );
};
