import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ActivityState {
  textSlideLink: TextSlideLinkProps;
  isActivityProjected: boolean;
  previewMode: PreviewModeProps;
  reviewMode: ReviewModeProps;
  studentSendButtonState: StudentSendButtonStateProps;
  favoriteAnswer: FavoriteAnswerProps;
  navigationState: NavigationStateProps;
}

interface TextSlideLinkProps {
  isOpen: boolean;
}

interface NavigationStateProps {
  maximumStepReached: number;
  hasNavigatedBack: boolean;
  currentStep: number;
}
export interface PreviewModeProps {
  isPreviewMode: boolean;
  isStudentView: boolean;
}

export interface ReviewModeProps {
  isReviewMode: boolean;
}

export interface FavoriteAnswerProps {
  isShowingFavoriteAnswer: boolean;
}

export interface StudentSendButtonStateProps {
  isLoading: boolean;
  isDisabled: boolean;
  isAnswered: boolean;
  isHidden: boolean;
}

const initialState: ActivityState = {
  textSlideLink: {
    isOpen: false,
  },
  previewMode: {
    isPreviewMode: false,
    isStudentView: false,
  },
  reviewMode: {
    isReviewMode: false,
  },
  studentSendButtonState: {
    isLoading: false,
    isDisabled: false,
    isAnswered: false,
    isHidden: false,
  },
  isActivityProjected: false,
  favoriteAnswer: {
    isShowingFavoriteAnswer: false,
  },
  navigationState: {
    maximumStepReached: 0,
    currentStep: 0,
    hasNavigatedBack: false,
  },
};

const activity = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    handleTextSlideLink: (state, action: PayloadAction<TextSlideLinkProps>) => {
      state.textSlideLink = action.payload;
    },

    handleActivityProjected: (
      state,
      action: PayloadAction<ActivityState['isActivityProjected']>
    ) => {
      state.isActivityProjected = action.payload;
    },
    handlePreviewMode: (
      state,
      action: PayloadAction<Partial<PreviewModeProps>>
    ) => {
      state.previewMode = { ...state.previewMode, ...action.payload };
    },
    handleReviewMode: (
      state,
      action: PayloadAction<Partial<ReviewModeProps>>
    ) => {
      state.reviewMode = { ...state.reviewMode, ...action.payload };
    },
    handleStudentSendButtonState: (
      state,
      action: PayloadAction<Partial<StudentSendButtonStateProps>>
    ) => {
      state.studentSendButtonState = {
        ...state.studentSendButtonState,
        ...action.payload,
      };
    },
    handleFavoriteAnswer: (
      state,
      action: PayloadAction<Partial<FavoriteAnswerProps>>
    ) => {
      state.favoriteAnswer = {
        ...state.favoriteAnswer,
        ...action.payload,
      };
    },
    handleNavigationState: (
      state,
      action: PayloadAction<Partial<NavigationStateProps>>
    ) => {
      state.navigationState = {
        ...state.navigationState,
        ...action.payload,
      };
    },
    resetReviewMode: state => {
      state.reviewMode = initialState.reviewMode;
    },
    resetPreviewMode: state => {
      state.previewMode = initialState.previewMode;
    },
    resetStudentSendButtonState: state => {
      state.studentSendButtonState = initialState.studentSendButtonState;
    },
    resetNavigationState: state => {
      state.navigationState = initialState.navigationState;
    },
  },
});

export const ActivitySelectors = {
  getTextSlideLink: (state: RootState) => state.activity.textSlideLink,
  getIsActivityProjected: (state: RootState) =>
    state.activity.isActivityProjected,
  getPreviewMode: (state: RootState) => state.activity.previewMode,
  getReviewMode: (state: RootState) => state.activity.reviewMode,
  getStudentSendButtonState: (state: RootState) =>
    state.activity.studentSendButtonState,
  getFavoriteAnswer: (state: RootState) => state.activity.favoriteAnswer,
  getNavigationState: (state: RootState) => state.activity.navigationState,
};

export const {
  handleTextSlideLink,
  handleActivityProjected,
  handlePreviewMode,
  handleReviewMode,
  handleStudentSendButtonState,
  handleFavoriteAnswer,
  resetStudentSendButtonState,
  resetReviewMode,
  resetPreviewMode,
  handleNavigationState,
  resetNavigationState,
} = activity.actions;

export const ActivityReducer = activity.reducer;
