import { BottomCard } from '@components/atoms';
import {
  ChallengeContent,
  SlideControls,
  SlideControlsProps,
} from '@components/organisms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import {
  UserRole,
  Reto,
  RetosAnalytics,
  retoStartLabel,
} from '@domain/constants';
import { useEffect } from 'react';
import { trackGA4Events } from '@data/utils';
import { useRetos } from '@hooks/useRetos';
import { useDueDate } from '@hooks/useDueDate';

interface ChallengeSlideProps extends SlideControlsProps {
  challenge: Reto;
}

export const ChallengeSlide = ({
  nextButtonText,
  onClickNext,
  challenge,
}: ChallengeSlideProps) => {
  const role = useSelector(UserSelectors.getRole);
  const { updateRetoDueDate } = useRetos();
  const { dueDate } = useDueDate({
    channel: `due-date-channel-${challenge.id}`,
    slideId: challenge.id,
  });

  const handleNextClick = async () => {
    if (role === UserRole.CATECHIST && dueDate) {
      await updateRetoDueDate(challenge.id, dueDate);
    }
    onClickNext();
  };

  useEffect(() => {
    if (role === UserRole.CATECHIST) {
      trackGA4Events(
        RetosAnalytics.categories.retoStart,
        RetosAnalytics.actions.reto,
        retoStartLabel(
          new Date().toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          challenge.id.toString()
        )
      );
    }
  }, [challenge.id, role]);

  return (
    <div className='mt-32 flex flex-col justify-between'>
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner justify-between !p-6 overflow-hidden flex-1'>
        <div className='flex flex-1 flex-col justify-between pb-12'>
          <ChallengeContent challenge={challenge} />
          <SlideControls
            onClickNext={handleNextClick}
            nextButtonText={nextButtonText}
          />
        </div>
      </BottomCard>
    </div>
  );
};
