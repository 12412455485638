import { useMemo } from 'react';
import {
  LESSON_BACKGROUND_COLOR_STORAGE_KEY,
  STATES_STR,
} from '@domain/constants';
import { TacoTruck, NotStarted, Skipped, Finished } from '@assets/img';
import { PathLeft, PathRight } from '@assets/svg';
import { Typography } from '@components/atoms';
import tailwindConfig from '../../../../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { setModalLesson } from '@store/slices';

const fullConfig = resolveConfig(tailwindConfig);

export interface LessonPathCardProps {
  publicUid: string;
  state: STATES_STR;
  title: string;
  description: string;
  className?: string;
  index: number;
  videoLinkOne?: string;
  videoLinkTwo?: string;
}

interface LessonPathCardTextProps {
  name: string;
}

const getSurfaceColor = () => fullConfig.theme.colors.secondary.surface[200];

const getBackgroundColor = () => {
  return (
    localStorage.getItem(LESSON_BACKGROUND_COLOR_STORAGE_KEY) ||
    getSurfaceColor()
  );
};

const useBackgroundColor = () => {
  return useMemo(() => getBackgroundColor(), []);
};

const LessonPathCardText = ({ name }: LessonPathCardTextProps) => {
  return (
    <div className='absolute top-[110px] grid w-full flex-col place-items-center'>
      <Typography
        variant='span'
        className='line-clamp-2 w-2/4 text-center text-[16px] font-bold leading-[20px]'
      >
        {name}
      </Typography>
    </div>
  );
};

export const LessonPathCard = ({
  state,
  title,
  index,
  description,
  publicUid,
  videoLinkOne,
  videoLinkTwo,
}: LessonPathCardProps) => {
  const dispatch = useDispatch();
  const backgroundColor = useBackgroundColor();
  const surfaceColor = getSurfaceColor();

  const isEven = index % 2 === 0;
  const iconBackgroundColor =
    state === STATES_STR.finished ? backgroundColor : surfaceColor;
  const pathBackgroundColor =
    state === STATES_STR.notStarted ? surfaceColor : backgroundColor;
  const doFillAnimation = state == STATES_STR.notStarted ? false : true;
  const doThrobAnimation = state == STATES_STR.inProgress;

  const PathComponent = isEven ? (
    <div>
      <PathRight
        fillColor={pathBackgroundColor}
        animate={doFillAnimation}
        index={index}
      />
    </div>
  ) : (
    <PathLeft
      fillColor={pathBackgroundColor}
      animate={doFillAnimation}
      index={index}
    />
  );
  const positionClass = isEven ? '-right-2' : '-left-2';

  const image =
    state === STATES_STR.notStarted
      ? NotStarted
      : state === STATES_STR.inProgress
        ? TacoTruck
        : state === STATES_STR.skipped
          ? Skipped
          : Finished;

  const border =
    state === STATES_STR.finished ? `1px solid ${pathBackgroundColor}` : '';

  const handleClickLesson = () => {
    dispatch(
      setModalLesson({
        isOpen: true,
        title,
        description,
        publicUid,
        state,
        videoLinkOne,
        videoLinkTwo,
      })
    );
  };

  return (
    <div className='relative'>
      {PathComponent}
      <motion.div
        onClick={handleClickLesson}
        initial={{ scale: 1 }}
        animate={{ scale: doThrobAnimation ? 1.1 : 1 }} // Throbbing effect: slightly larger and less opaque
        transition={{
          delay: 0,
          duration: 0.5, // Duration of one pulse cycle
          repeat: Infinity, // Loop the animation indefinitely
          repeatType: 'reverse', // Reverses the animation (scale down after scaling up)
          ease: 'easeInOut', // Smooth easing
        }}
        style={{ backgroundColor: iconBackgroundColor, border }}
        className={`absolute top-20 z-10 grid h-[88px] w-[88px] place-items-center rounded-full  shadow-lgInner ${positionClass}`}
      >
        <img src={image} className='w-16' />
      </motion.div>
      <LessonPathCardText name={title} />
    </div>
  );
};
