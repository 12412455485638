import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@components/atoms';
import { useEffect, useMemo, useState } from 'react';
import {
  MdExpandLess,
  MdExpandMore,
  MdFavorite,
  MdLocalShipping,
} from 'react-icons/md';
import { TFunction } from 'i18next';
import {
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
  UserRole,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';

interface OpenQuestionResponsesProps {
  responses: ResponsesProps[];
  role: UserRole;
  handleLike: (responseId: string) => void;
}

export interface ResponsesProps {
  id: string;
  username: string;
  response: string;
  likes?: { count: number; userIds: number[] };
}

interface ResponseContainerProps {
  username: string;
  id: string;
  response: string;
  t: TFunction<'components/molecules/openQuestionResponse'>;
  hideFavoriteButton?: boolean;
  hideFavoriteCounter?: boolean;
  role: UserRole;
  handleLike: (responseId: string) => void;
  likes: { count: number; userIds: string[] };
}

const ResponseContainer = ({
  username,
  response,
  t,
  hideFavoriteButton,
  hideFavoriteCounter,
  role,
  handleLike,
  id,
  likes,
}: ResponseContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const userId = useSelector(UserSelectors.getId);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFavorite = (responseId: string) => {
    if (role === UserRole.STUDENT) {
      handleLike(responseId);
    }
  };

  useEffect(() => {
    if (likes?.userIds) {
      const isFavorite = likes.userIds.includes(userId);
      setIsFavorite(isFavorite);
    }
  }, [likes?.userIds, userId]);

  return (
    <div className='flex w-full flex-col justify-between rounded-xl bg-secondary-surface-200 p-2.5'>
      <div className='space-y-2'>
        <Typography variant='p' className='font-bold'>
          {username}
        </Typography>
        <Typography
          variant='p'
          className={`${!isExpanded ? 'line-clamp-3' : ''} leading-[20px]`}
        >
          {response}
        </Typography>
      </div>

      <div className='flex items-center justify-between'>
        {response && response?.length > 100 ? (
          <Button
            style='link'
            text={isExpanded ? t('buttons.less') : t('buttons.more')}
            onClick={toggleExpand}
            iconRight={
              isExpanded ? (
                <MdExpandLess
                  size={24}
                  className='text-secondary-buttons-100'
                />
              ) : (
                <MdExpandMore
                  size={24}
                  className='text-secondary-buttons-100'
                />
              )
            }
          />
        ) : (
          <span></span>
        )}

        {!hideFavoriteButton && likes && (
          <div className='flex items-center gap-2'>
            {!hideFavoriteCounter && likes?.count && (
              <Typography variant='span' className='font-bold'>
                {likes.count}
              </Typography>
            )}
            <MdFavorite
              onClick={() => handleFavorite(id)}
              size={24}
              className={`${
                isFavorite || role === UserRole.CATECHIST
                  ? 'text-secondary-phasefour-400'
                  : 'text-secondary-buttons-500'
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const OpenQuestionResponses = ({
  responses,
  role,
  handleLike,
}: OpenQuestionResponsesProps) => {
  const { t } = useTranslation('components/molecules/openQuestionResponse');
  const studentFullName = useSelector(UserSelectors.getFullName);
  const studentResponse =
    localStorage.getItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY) || '';

  const filteredResponses = useMemo(() => {
    return responses.filter(response => response.response !== studentResponse);
  }, [responses, studentResponse]);

  return (
    <div className='h-full space-y-2 overflow-auto rounded-xl bg-secondary-surface-100 p-4'>
      {role === UserRole.STUDENT && studentResponse && (
        <div className='mb-4 space-y-2'>
          <Typography variant='span' className='font-bold'>
            {t('myResponse')}
          </Typography>
          <ResponseContainer
            username={studentFullName}
            response={studentResponse}
            t={t}
            hideFavoriteButton
          />
        </div>
      )}
      <Typography variant='span' className='font-bold'>
        {t('classResponses')}
      </Typography>
      <div className='grid h-full grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3'>
        {filteredResponses && filteredResponses.length >= 1 ? (
          filteredResponses.map(response => {
            return (
              <ResponseContainer
                key={response.id}
                id={response.id}
                username={response.username}
                response={response.response}
                likes={response.likes}
                t={t}
                role={role}
                hideFavoriteCounter={role === UserRole.STUDENT}
                handleLike={handleLike}
              />
            );
          })
        ) : role === UserRole.STUDENT ? (
          <SlideAnswerSubmitted className='!border-0' />
        ) : (
          <div className='col-span-1 flex h-full flex-col items-center justify-center gap-2 tablet:col-span-2 desktop:col-span-3 '>
            <MdLocalShipping size={24} className='text-secondary-text-200' />
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('waitingForResponses')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
