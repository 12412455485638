import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { Card, Typography } from '@components/atoms';
import { useNavigate } from 'react-router-dom';
import { NumberOne } from '@assets/img';
import { useTranslation } from 'react-i18next';

interface CardActiveLesson {
  publicUid: string;
  title: string;
  description: string;
  modulePublicUid: string;
  coursePublicUid: string;
}

export const CardActiveLesson = ({
  publicUid,
  title,
  description,
  modulePublicUid,
  coursePublicUid,
}: CardActiveLesson) => {
  const navigate = useNavigate();
  const { t } = useTranslation('components/molecules/cardActiveLesson');

  const navigateToInProgressActivity = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.activity
        .replace(':classId', coursePublicUid)
        .replace(':moduleId', modulePublicUid)
        .replace(':lessonId', publicUid)
    );
  };

  return (
    <Card
      className='relative mb-4 overflow-hidden bg-gradient-to-r from-secondary-phasesix-400 to-secondary-phasesix-500 !shadow-none [&_*]:!text-secondary-buttons-600'
      onClick={navigateToInProgressActivity}
    >
      <div className='flex flex-col gap-2'>
        <Typography
          variant='span'
          className='text-[16px] font-medium leading-[20px]'
        >
          {title}
        </Typography>
        <Typography variant='span'>{description}</Typography>
        <Typography variant='span' className='mt-2 font-bold underline'>
          {t('joinLesson')}
        </Typography>
      </div>
      <img src={NumberOne} className='absolute -bottom-5 right-0 h-28'></img>
    </Card>
  );
};
