import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MdChevronLeft } from 'react-icons/md';
import { ShopContainer, Typography } from '@components/atoms';
import { ShopItemList } from '@components/organisms';
import { HomeTemplate, ModalSheetCollectible } from '@components/templates';
import { useShop } from '@hooks/index';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotificationsDispatcher, trackGA4PageView } from '@data/utils';
import { PAGE_NAMES, ToastNotificationType } from '@domain/constants';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { motion } from 'framer-motion';

export const ShopCollectiblesPage = () => {
  const [collectible, setCollectible] = useState<Record<
    string,
    string | number
  > | null>(null);

  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const { t } = useTranslation('pages/home/shop/shopCollectiblesPage');
  const { getShopItemsByType, navigateToShop, purchaseShopItemMutation } =
    useShop();
  const { data, isLoading, isPending, refetch } = useQuery({
    queryKey: ['shopItemsByCollectible'],
    queryFn: () => getShopItemsByType('collectible'),
  });

  const handlePurchaseCollectible = async () => {
    try {
      await purchaseShopItemMutation.mutateAsync(collectible.id);

      NotificationsDispatcher({
        type: ToastNotificationType.success,
        title: t('toastNotifications.success.title'),
        message: t('toastNotifications.success.message'),
      });

      setCollectible(null);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shopCollectibles,
      title: PAGE_NAMES.shopCollectibles,
    });
  }, []);

  return (
    <HomeTemplate
      hideBottomNavigation
      childrenClassname='!bg-secondary-surface-100'
    >
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <motion.div
          initial={ { opacity: 0} }
          animate={{ opacity: 1 }}
          transition={{ 
            delay: 0.3,
            duration: 0.5,
            type: "spring", 
            stiffness: 200, 
            damping: 5 }}
           >
        <MdChevronLeft
          size={24}
          className='cursor-pointer text-secondary-buttons-500'
          onClick={navigateToShop}
        />
        </motion.div>
        <motion.div
          initial={{ y: '-130%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }}       // Slide to the original position
          transition={{
            duration: 0.3,
            ease: "easeInOut"
          }}
          >
        <Typography variant='h1'>{t('title')}</Typography>
        </motion.div>
        <motion.div
          initial={ {opacity: 0} }
          animate={ { opacity: 1} }
          transition={{
            duration: 0.7,
            ease: "easeInOut"
          }}
          >
        <ShopItemList
          items={data}
          studentPoints={studentAvailablePoints}
          onClick={item => setCollectible(item)}
        />
        </motion.div>
        <ModalSheetCollectible
          onClose={() => setCollectible(null)}
          handleClickLoading={purchaseShopItemMutation.isPending}
          onClick={handlePurchaseCollectible}
          isOpen={!!collectible}
          {...collectible}
        />
      </ShopContainer>
    </HomeTemplate>
  );
};
