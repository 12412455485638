import { ReminderCard } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { Carousel, Typography } from '@components/atoms';
import { ReactNode } from 'react';

interface GuidesListProps {
  guides: GuideCardProps[];
}

interface GuideCardProps {
  title: string;
  icon: ReactNode;
  link: string;
  subtitle: string;
}

export const GuidesList = ({ guides }: GuidesListProps) => {
  const { t } = useTranslation('components/organisms/guidesList');

  return (
    <article className='mt-4 flex flex-col gap-4'>
      <Typography variant='h1'>{t('title')}</Typography>
      <Carousel
        data={guides}
        CardComponent={ReminderCard}
        getCardProps={guide => ({
          onClick: () => window.open(guide.link, '_blank'),
        })}
      />
    </article>
  );
};
