import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ModalLessonNavigationState {
  isOpen: boolean;
  isShowing: boolean;
}

const initialState: ModalLessonNavigationState = {
  isOpen: false,
  isShowing: false,
};

const modalLessonNavigation = createSlice({
  name: 'modalLessonNavigation',
  initialState,
  reducers: {
    handleModalLessonNavigation: (
      state,
      action: PayloadAction<ModalLessonNavigationState['isOpen']>
    ) => {
      state.isOpen = action.payload;
    },
    handleModalLessonNavigationShowing: (
      state,
      action: PayloadAction<ModalLessonNavigationState['isShowing']>
    ) => {
      state.isShowing = action.payload;
    },
    resetModalLessonNavigation: () => initialState,
  },
});

export const ModalLessonNavigationSelectors = {
  getIsOpen: (state: RootState) => state.modalLessonNavigation.isOpen,
  getIsShowing: (state: RootState) => state.modalLessonNavigation.isShowing,
};

export const {
  handleModalLessonNavigation,
  resetModalLessonNavigation,
  handleModalLessonNavigationShowing,
} = modalLessonNavigation.actions;

export const ModalLessonNavigationReducer = modalLessonNavigation.reducer;
