import { StatisticsCard, TabNavigation } from '@components/organisms';
import { HomeTemplate, BadgesList } from '@components/templates';
import { TabLayout, Typography } from '@components/atoms';
import { useQuery } from '@tanstack/react-query';
import { useAchievements } from '@hooks/index';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { trackGA4PageView } from '@data/utils';
import { useEffect } from 'react';
import { PAGE_NAMES } from '@domain/constants';
import { useLocation } from 'react-router-dom';

export const MyProgressPage = () => {
  const { getAchievements, getAchievedBadges } = useAchievements();
  const { t } = useTranslation(
    'pages/home/home/classes/myProgress/myProgressPage'
  );
  const location = useLocation();

  const {
    data: dataAchievements,
    isLoading: isLoadingAchievedBadges,
    isPending: isPendingAchievedBadges,
  } = useQuery({
    queryKey: ['getAchievedBadges'],
    queryFn: getAchievedBadges,
    retry: false,
  });

  const hasCompletedGetAchievements = dataAchievements !== undefined;

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getAchievements'],
    queryFn: getAchievements,
    retry: false,
    enabled: !!hasCompletedGetAchievements,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.myProgress,
    });
  }, [location.pathname]);

  const isAnyLoading =
    isLoading ||
    isPending ||
    isLoadingAchievedBadges ||
    isPendingAchievedBadges;

  return (
    <HomeTemplate
      childrenClassname='!px-0 !pb-0 !mb-20'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout className='flex flex-col' isLoading={isAnyLoading}>
        <motion.div
          initial={{ y: '-50%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }} // Slide to the original position
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
        >
          <Typography variant='h1' className='mb-4'>
            {t('achievements')}
          </Typography>
        </motion.div>
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.2,
          }}
        >
          <StatisticsCard stats={data?.stats} />
        </motion.div>
        <BadgesList badges={data?.achievements} stats={data?.stats} />
      </TabLayout>
    </HomeTemplate>
  );
};
