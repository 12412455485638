import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handlePreviewMode } from '@store/slices';
import { getLessonStorage } from '@data/utils';
import { LessonTemplate, SlideContent, Slide } from '@components/templates';
import { useLessons } from '@hooks/index';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';

export const PreviewActivityPage = () => {
  const { title, description, color, backgroundColor } = getLessonStorage();
  const { lessonId, moduleId, classId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLessonDetails } = useLessons();

  const [step, setStep] = useState(0);

  const { data, isLoading, isPending, isError } = useQuery({
    queryKey: ['getLessonDetails', lessonId],
    queryFn: () => getLessonDetails(lessonId!),
    retry: false,
  });

  useLayoutEffect(() => {
    dispatch(handlePreviewMode({ isPreviewMode: true }));
  }, [dispatch]);

  if (isError) {
    throw new Error();
  }

  const currentContent = data?.lesson.content[step];
  const type = currentContent?.type;
  const subtype = currentContent?.subtype;

  const isDataLoading = isLoading || isPending;

  const navigateToLessons = useCallback(() => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  }, [classId, moduleId, navigate]);

  useEffect(() => {
    if (step === data?.lesson.content.length) {
      navigateToLessons();
    }
  }, [step, data?.lesson.content.length, navigateToLessons]);

  return (
    <>
      <LessonTemplate
        title={title}
        subtitle={description}
        style={{ backgroundColor, color }}
        clickCloseButton={navigateToLessons}
        isLoading={isDataLoading}
        childrenClassName='!mx-0 !pt-0 bg-secondary-surface-100'
      >
        {data && step < data.lesson.content.length && (
          <Slide
            currentStep={step + 1}
            maxSteps={data.lesson.content.length}
            type={type}
            subtype={subtype}
            nextButtonText={'Next'}
            onClickNext={() => setStep(step + 1)}
            onShowResults={undefined}
            showResults={undefined}
          >
            <SlideContent
              slide={currentContent}
              channel={'si'}
              showResults={false}
              onClickNext={() => {}}
            />
          </Slide>
        )}
      </LessonTemplate>
    </>
  );
};
