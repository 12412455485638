import daisyui from 'daisyui';
import plugin from 'tailwindcss/plugin';
/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  daisyui: {
    themes: ['light'],
  },
  theme: {
    screens: {
      tablet: '648px',
      laptop: '1024px',
      desktop: '1280px',
    },
    boxShadow: {
      inner: 'inset 0px 4px 4px 0px rgba(71, 71, 76, 0.16)',
      button: '0px 4px 4px 0px rgba(71, 71, 76, 0.24)',
      general: '0px 4px 4px 0px rgba(71, 71, 76, 0.24)',
      lgInner: 'inset 0px 0px 8px 0px rgba(71, 71, 76, 0.24)',
      smInner: 'inset 0px 2px 4px 0px rgba(71, 71, 76, 0.24)',
      xsInner: 'inset 0px 0px 4px 0px rgba(71, 71, 76, 0.16)',
      none: 'none',
    },
    extend: {
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-radial-top':
          'radial-gradient(ellipse at 50% 30%, var(--tw-gradient-from) 0%, var(--tw-gradient-to) 100%)',
        awning: "url('/src/ui/assets/svg/awning.svg')",
      },
      borderWidth: {
        DEFAULT: '1px',
      },
      fontFamily: {
        body: ['Roboto', 'sans-serif'],
        heading: ['Freeman', 'sans-serif'],
      },
      userDrag: {
        none: 'user-drag: none; -webkit-user-drag: none; user-select: none; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;',
      },
      colors: {
        primary: {
          100: '#F7F2FC',
          200: '#DCCAED',
          300: '#A57ACF',
          350: '#8B3ED8',
          400: '#6E2AB1',
          500: '#58228E',
        },
        secondary: {
          phaseone: {
            400: '#B12A91',
            500: '#7D2976',
          },
          phasetwo: {
            400: '#00C4C4',
            500: '#00ABAB',
          },
          phasethree: {
            400: '#F7C226',
            500: '#CCA01F',
          },
          phasefour: {
            400: '#E3481E',
            500: '#C43F1A',
          },
          phasefive: {
            400: '#C54D29',
            500: '#9E3E21',
          },
          phasesix: {
            400: '#87BA1B',
            500: '#6C9516',
          },
          text: {
            400: '#6E2AB1',
            300: '#47474C',
            200: '#999999',
            150: '#DBDDDF',
            100: '#FBFBFD',
          },
          buttons: {
            100: '#6E2AB1',
            200: '#DCCAED',
            300: '#58228E',
            400: '#999999',
            500: '#47474C',
            600: '#FBFBFD',
            700: '#848486',
          },
          achievements: {
            50: '#999999',
            100: '#6E2AB1',
            200: '#00ABAB',
            300: '#E3481E',
            400: '#87BA1B',
            500: '#B12A91',
            600: '#0977B7',
            700: '#FB6721',
            800: '#ED254E',
            900: '#FF07A9',
            950: '#F7C226',
          },
          semantic: {
            information: {
              200: '#AFC0F2',
              300: '#708FE7',
              400: '#1D4ED8',
            },
            warning: {
              200: '#FFD4A0',
              300: '#FFB55F',
              400: '#FF8D07',
            },
            success: {
              200: '#ADE3C9',
              300: '#6ECA9D',
              400: '#19A863',
            },
            error: {
              200: '#E89D9D',
              300: '#D15A5A',
              400: '#B30000',
            },
          },
          surface: {
            100: '#F0F0FC',
            200: '#FBFBFD',
          },
        },
      },
    },
  },
  plugins: [
    daisyui,
    plugin(function ({ addVariant }) {
      addVariant('progress-unfilled', ['&::-webkit-progress-bar', '&']);
      addVariant('progress-filled', [
        '&::-webkit-progress-value',
        '&::-moz-progress-bar',
      ]);
    }),
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        '.no-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
        '.no-scrollbar': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },

        '.text-wrap-ellipsis': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '.user-drag-none': {
          userDrag: 'none',
          '-webkit-user-drag': 'none',
        },
      };
      addUtilities(newUtilities);
    }),
  ],
};
