import { ModalSheet, ModalSheetProps } from '@components/molecules';
import { Button, Typography, InnerShadowContainer } from '@components/atoms';
import { arcVitral, TacoIcon } from '@assets/svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'src/data/store/slices';
import { MdLock } from 'react-icons/md';

interface ModalSheetCollectibleProps extends ModalSheetProps {
  name: string;
  price: number;
  image: string;
  description: string;
  studentPoints: number;
  onClick?: () => void;
  isPurchased: boolean;
  handleClickLoading?: boolean;
  link: string;
  hidePrice?: boolean;
}

export const ModalSheetCollectible = ({
  isOpen,
  name,
  price,
  image,
  description,
  studentPoints,
  onClick,
  onClose,
  link,
  handleClickLoading,
  isPurchased,
  hidePrice,
}: ModalSheetCollectibleProps) => {
  const disabled = Number(price) > Number(studentPoints) && !isPurchased;

  const { t } = useTranslation('components/templates/modalSheetCollectible');
  const studentTacoPoints = useSelector(UserSelectors.getAvailablePoints);

  const handleClick = () => {
    if (isPurchased) {
      window.open(link, '_blank');
      return;
    }

    onClick?.();
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={onClose}
      className='bg-gradient-radial-top from-[#FFC349] to-[#C16800]'
    >
      <img
        src={arcVitral}
        alt='arc'
        className='absolute -top-7 right-0 select-none user-drag-none '
      />
      <div className='flex flex-col items-center justify-center gap-4 p-4'>
        <img src={image} alt='collectible' className='h-52 w-52' />
        <Typography
          variant='h1'
          className='text-[32px] uppercase leading-[36px] !text-secondary-text-100'
        >
          {name}
        </Typography>

        {!hidePrice && (
          <div className='flex items-center gap-2 rounded-lg bg-secondary-surface-200 p-2'>
            {studentTacoPoints > price ? (
              <img src={TacoIcon} alt='Taco points icon'></img>
            ) : (
              <MdLock size={16} className='flex-shrink-0' />
            )}
            <Typography
              variant='span'
              className='text-[16px] font-medium leading-[20px]'
            >
              {price?.toString()}
            </Typography>
          </div>
        )}

        <InnerShadowContainer className='w-full px-8 text-center'>
          <Typography variant='p' className='leading-[18px]'>
            {description}
          </Typography>
        </InnerShadowContainer>

        <Button
          type='button'
          style='primary'
          className='w-full'
          disabled={disabled}
          loading={handleClickLoading}
          onClick={handleClick}
          text={
            isPurchased
              ? t('learnMore')
              : disabled
                ? t('notEnoughPoints')
                : t('buyCollectible')
          }
        />
      </div>
    </ModalSheet>
  );
};
