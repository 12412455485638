import { Typography, BorderContainer } from '@components/atoms';
import { MdLink } from 'react-icons/md';

interface LinkButtonProps {
  onClick: () => void;
  text: string;
}

export const LinkButton = ({ onClick, text }: LinkButtonProps) => {
  return (
    <BorderContainer
      role='button'
      onClick={onClick}
      containerStyles='flex !w-fit items-center gap-2 border border-primary-200 bg-primary-100'
    >
      <MdLink size={24} className='flex-shrink-0 text-secondary-buttons-500' />
      <Typography
        variant='span'
        className='!text-secondary-semantic-information-400'
      >
        {text}
      </Typography>
    </BorderContainer>
  );
};
