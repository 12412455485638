import { BottomCard, Typography } from '@components/atoms';
import {
  SlideControls,
  SlideControlsProps,
  WaitingRoomContent,
} from '@components/organisms';
import { useSelector } from 'react-redux';
import { StudentsSelectors, UserSelectors } from '@store/slices';
import {
  UserRole,
  WAITING_ROOM_TIMER_REFRESH_INTERVAL,
} from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { getClassStorage, trackGA4Events } from '@data/utils';
import { usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import {
  joinLessonLabel,
  LessonsAnalytics,
} from '@domain/constants/googleAnalytics/lessons';

interface WaitingRoomSlideProps extends SlideControlsProps {
  channelName: string;
  lessonId: string;
}

export const WaitingRoomSlide = ({
  nextButtonText,
  onClickNext,
  channelName,
  lessonId,
}: WaitingRoomSlideProps) => {
  const role = useSelector(UserSelectors.getRole);
  const userId = useSelector(UserSelectors.getId);
  const [activeStudents, setActiveStudents] = useState<
    Record<string, string | number>[]
  >([]);

  const { t } = useTranslation('components/templates/waitingRoomSlide');
  const { publicUid } = getClassStorage();
  const waitingRoom = useSelector(StudentsSelectors.getWaitingRoom);

  const pubnub = usePubNub();

  useEffect(() => {
    const fetchHereNow = async () => {
      const hereNowResponse = await pubnub.hereNow({
        channels: [channelName],
        includeUUIDs: true,
      });

      const occupants = hereNowResponse.channels[channelName].occupants;

      const filteredStudents = waitingRoom.filter(student =>
        occupants.find(occupant => occupant.uuid === student.id.toString())
      );

      setActiveStudents(filteredStudents);
    };

    const delayFetch = setTimeout(fetchHereNow, 1000);

    const intervalId = setInterval(
      fetchHereNow,
      WAITING_ROOM_TIMER_REFRESH_INTERVAL
    );

    return () => {
      clearInterval(intervalId);
      clearInterval(delayFetch);
    };
  }, [channelName, pubnub, waitingRoom]);

  useEffect(() => {
    if (!userId || !lessonId) return;
    trackGA4Events(
      LessonsAnalytics.categories.joinLesson,
      LessonsAnalytics.actions.lesson,
      joinLessonLabel(
        new Date().toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }),
        userId.toString(),
        lessonId
      )
    );
  }, [lessonId, userId]);

  return (
    <div className='flex h-dvh flex-col justify-between !bg-secondary-surface-100 pt-[100px]'>
      <div className='my-8 grid place-items-center gap-2 '>
        {role === UserRole.CATECHIST && (
          <>
            <Typography variant='span'>{t('classCode')}</Typography>
            <Typography
              variant='span'
              className='text-[22px] font-bold leading-[24px]'
            >
              {publicUid.toUpperCase()}
            </Typography>
          </>
        )}
      </div>
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner justify-between !p-6 overflow-hidden'>
        <div className='overflow-auto'>
          <WaitingRoomContent students={activeStudents} />
          <SlideControls
            onClickNext={onClickNext}
            nextButtonText={nextButtonText}
          />
        </div>
      </BottomCard>
    </div>
  );
};
