export const PrayerGameAnalytics = {
  // ACTIONS
  actions: {
    prayerGame: 'Prayer Game',
  },

  // CATEGORIES
  categories: {
    gameFinished: 'Game Finished',
    gameStart: 'Game Started',
    gameQuit: 'Game Quit',
  },

  // LABELS
  labels: {
    gameFinished: ':date; :userID; :gameID, :correctAnswers; :totalQuestions',
    gameStarted: ':date; :userID; :gameID',
    gameQuit: ':date; :userID; :gameID',
  },
};

export const gameFinishedLabel = (
  date: string,
  userId: string,
  gameId: string,
  correctAnswers: number,
  totalQuestions: number
) => {
  return PrayerGameAnalytics.labels.gameFinished
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':gameID', gameId)
    .replace(':correctAnswers', correctAnswers.toString())
    .replace(':totalQuestions', totalQuestions.toString());
};

export const gameStartedLabel = (
  date: string,
  userId: string,
  gameId: string
) => {
  return PrayerGameAnalytics.labels.gameStarted
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':gameID', gameId);
};

export const gameQuitLabel = (date: string, userId: string, gameId: string) => {
  return PrayerGameAnalytics.labels.gameQuit
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':gameID', gameId);
};
