import { Card, Typography } from '@components/atoms';
import { getLessonTextSubtype } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { MdArrowForward } from 'react-icons/md';

interface NavigationCardProps {
  isPassedSlide: boolean;
  slide: Record<string, string | number>;
  onClick: () => void;
}

export const NavigationCard = ({
  isPassedSlide,
  slide,
  onClick,
}: NavigationCardProps) => {
  const { t } = useTranslation('common');

  return (
    <Card key={slide.id} onClick={onClick}>
      <div className='flex items-center justify-between gap-4'>
        <input
          type='checkbox'
          className='checkbox [--chkbg:#6E2AB1] [--chkfg:#FBFBFB]'
          checked={isPassedSlide}
          onChange={() => {}}
          onClick={() => {}}
        />
        <div className='flex flex-col'>
          <Typography variant='span' className='font-bold'>
            {slide.title}
          </Typography>
          <Typography variant='span'>
            {getLessonTextSubtype(slide.type, slide.subtype, t)}
          </Typography>
        </div>
      </div>
      {isPassedSlide && (
        <MdArrowForward
          size={16}
          className='flex-shrink-0 text-secondary-buttons-500'
        />
      )}
    </Card>
  );
};
