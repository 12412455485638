import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  containerStyles?: string;
}

export const BottomCard = ({ children, containerStyles }: CardProps) => {
  return (
    <div
      className={`flex h-full w-full flex-col rounded-t-xl bg-secondary-surface-100 px-6 py-8 ${containerStyles}`}
    >
      {children}
    </div>
  );
};
