import { ButtonProps, AnimatedButton } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivitySelectors,
  handlePreviewMode,
  UserSelectors,
} from 'src/data/store/slices';
import { ActivitySubtype, ActivityType, UserRole } from '@domain/constants';
import { FixedButtonWrapper } from '@components/molecules';

export interface SlideControlsProps
  extends Omit<ButtonProps, 'text' | 'type' | 'style'>,
    Omit<SlideControlOptionsProps, 'role'> {
  nextButtonText: string;
}

interface SlideControlOptionsProps {
  role: UserRole;
  type: ActivityType;
  subtype: ActivitySubtype;
  onShowResults?: (showResults: boolean) => void;
  showResults?: boolean;
  onClickNext: () => void;
}

const SlideControlOptions = ({
  role,
  type,
  subtype,
  onShowResults,
  showResults,
  onClickNext,
  ...rest
}: SlideControlOptionsProps) => {
  const previewMode = useSelector(ActivitySelectors.getPreviewMode);
  const reviewMode = useSelector(ActivitySelectors.getReviewMode);
  const studentSendButtonState = useSelector(
    ActivitySelectors.getStudentSendButtonState
  );
  const favoriteAnswerState = useSelector(ActivitySelectors.getFavoriteAnswer);

  const dispatch = useDispatch();

  const { t } = useTranslation('components/organisms/slideControls');

  const handleClickNext = () => {
    onClickNext();
    if (onShowResults && showResults) onShowResults(false);
  };

  let showResultsText;

  if (subtype === ActivitySubtype.VIDEO) {
    showResultsText = showResults ? t('hideVideo') : t('showVideo');
  } else {
    showResultsText = showResults ? t('hideResults') : t('showResults');
  }

  // Preview mode: Student view, next button (mock)
  if (previewMode.isPreviewMode) {
    return (
      <div className='flex w-full gap-4'>
        {type === ActivityType.ACTIVITY && (
          <AnimatedButton
            style='secondary'
            text={
              previewMode.isStudentView ? t('catechistView') : t('studentView')
            }
            onClick={() =>
              dispatch(
                handlePreviewMode({ isStudentView: !previewMode.isStudentView })
              )
            }
          />
        )}
        <AnimatedButton
          style='primary'
          text='Next'
          onClick={onClickNext}
          className='grow'
        />
      </div>
    );
  }

  if (reviewMode.isReviewMode) {
    return <AnimatedButton style='primary' text='Next' onClick={() => {}} />;
  }

  if (
    role === UserRole.CATECHIST &&
    (subtype !== ActivitySubtype.OPEN_QUESTION ||
      favoriteAnswerState.isShowingFavoriteAnswer)
  ) {
    return (
      <div className='flex w-full gap-4'>
        {onShowResults && !favoriteAnswerState.isShowingFavoriteAnswer && (
          <AnimatedButton
            style='secondary'
            type='button'
            text={showResultsText}
            className='max-w-fit !flex-1'
            onClick={() => onShowResults(!showResults)}
          />
        )}
        <AnimatedButton
          {...rest}
          style='primary'
          type='button'
          className='!flex-1'
          text={t('continue')}
          onClick={handleClickNext}
        />
      </div>
    );
  }

  // Student real mode
  if (
    role === UserRole.STUDENT &&
    type === ActivityType.ACTIVITY &&
    !showResults &&
    !studentSendButtonState.isHidden
  ) {
    return (
      <AnimatedButton
        form='slide-form'
        type='submit'
        disabled={
          studentSendButtonState.isDisabled || studentSendButtonState.isLoading
        }
        loading={studentSendButtonState.isLoading}
        style='primary'
        text={t('send')}
      />
    );
  }
};

export const SlideControls = ({
  onClickNext,
  onShowResults,
  showResults,
  subtype,
  type,
  ...rest
}: SlideControlsProps) => {
  const role = useSelector(UserSelectors.getRole);

  return (
    <FixedButtonWrapper>
      <SlideControlOptions
        role={role}
        type={type}
        subtype={subtype}
        onShowResults={onShowResults}
        showResults={showResults}
        onClickNext={onClickNext}
        {...rest}
      />
    </FixedButtonWrapper>
  );
};
