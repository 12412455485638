import { useCallback, useEffect } from 'react';
import { Layout, Typography } from '@components/atoms';
import { Virgin } from '@assets/svg';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useTranslation } from 'react-i18next';
import { SPLASH_PAGES_TIMER_INTERVAL } from '@domain/constants';

export const WelcomeBackPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages/splash/welcomeBackPage');

  const navigateToHome = useCallback(() => {
    navigate(PROTECTED_ROUTES_PATHS.home);
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      navigateToHome();
    }, SPLASH_PAGES_TIMER_INTERVAL);
    return () => clearInterval(interval);
  }, [navigateToHome]);

  return (
    <Layout
      isFlex
      containerStyles='!bg-secondary-phaseone-400 justify-between p-0'
    >
      <div className='mt-16 flex flex-col items-center gap-4'>
        <Typography
          variant='h1'
          className='text-[40px] leading-[44px] text-white'
        >
          {t('welcomeBack')}
        </Typography>
        <Typography
          variant='span'
          className='text-[20px] font-medium leading-[24px] text-white'
        >
          {t('phrases.virginMary')}
        </Typography>
      </div>
      <img className='h-[400px] w-full object-fill' src={Virgin}></img>
    </Layout>
  );
};
