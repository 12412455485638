import React, { useRef, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
  minDate: Date;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDate,
  onChange,
  minDate,
}) => {
  const CustomInput = forwardRef<
    HTMLInputElement,
    React.HTMLProps<HTMLInputElement>
  >(({ value, onClick }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      className='!w-full cursor-pointer rounded-lg border p-2 shadow-inner'
    />
  ));

  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      minDate={minDate}
      dateFormat='MMMM/dd/yyyy'
      showYearDropdown
      scrollableYearDropdown
      popperClassName='react-datepicker-popper'
      wrapperClassName='!w-full'
      customInput={<CustomInput />}
    />
  );
};
