import { useState } from 'react';
import { ButtonTabSelector } from '@components/molecules';

interface TabSelectorProps {
  tabs: Array<{
    id: string;
    label: string;
    route?: string;
  }>;
  useStateNavigation?: boolean;
  activeTab?: string;
  setActiveTab?: (id: string) => void;
}

export const TabSelector = ({
  tabs,
  useStateNavigation = false,
  activeTab,
  setActiveTab,
}: TabSelectorProps) => {
  const handleTabClick = (id: string) => {
    setActiveTab?.(id);
  };

  return (
    <nav className='flex w-full gap-2 rounded-xl bg-secondary-surface-200 p-2 shadow-inner'>
      {tabs.map(({ id, label, route }) => (
        <ButtonTabSelector
          key={id}
          label={label}
          route={useStateNavigation ? undefined : route}
          isActive={useStateNavigation ? activeTab === id : undefined}
          onClick={() => handleTabClick(id)}
        />
      ))}
    </nav>
  );
};
