import { ReactNode } from 'react';

interface FixedButtonWrapper {
  children: ReactNode;
}

export const FixedButtonWrapper = ({ children }: FixedButtonWrapper) => {
  return (
    <div className='fixed bottom-6 left-4 right-4 z-[100]'>{children}</div>
  );
};
