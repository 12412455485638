import { ActivityType, ActivitySubtype } from '@domain/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivitySelectors,
  handleStudentSendButtonState,
  UserSelectors,
} from '@store/slices';
import { Content } from '@domain/constants';
import { RenderImageSlide, RenderTextSlide, RenderVideoSlide } from './Slides';
import {
  RenderOpenQuestionActivity,
  RenderPinItActivity,
  RenderPollActivity,
  RenderQuizActivity,
  RenderWordCloudActivity,
} from './Activities';
import { useLayoutEffect } from 'react';

interface SlideContentProps {
  slide: Content;
  channel: string;
  showResults: boolean;
  onClickNext: () => void;
}

export const SlideContent = ({
  slide,
  channel,
  showResults,
  onClickNext,
}: SlideContentProps) => {
  const role = useSelector(UserSelectors.getRole);
  const previewMode = useSelector(ActivitySelectors.getPreviewMode);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(
      handleStudentSendButtonState({
        isLoading: false,
        isDisabled: false,
        isAnswered: false,
      })
    );
  }, [slide.id, dispatch]);

  switch (slide.type) {
    case ActivityType.SLIDE:
      switch (slide.subtype) {
        case ActivitySubtype.TEXT:
          return (
            <RenderTextSlide
              title={slide.title}
              textContent={slide.textContent!}
              type={slide.type}
              subtype={slide.subtype}
              link={slide.link}
            />
          );
        case ActivitySubtype.VIDEO:
          return (
            <RenderVideoSlide
              title={slide.title}
              videoURL={slide.videoURL!}
              type={slide.type}
              subtype={slide.subtype}
              textContent={slide.textContent}
              showVideo={showResults}
            />
          );
        case ActivitySubtype.IMAGE:
          return (
            <RenderImageSlide
              title={slide.title}
              imageURL={slide.imageURL!}
              type={slide.type}
              subtype={slide.subtype}
              textContent={slide.textContent}
            />
          );
        default:
          return <div>Unknown slide subtype</div>;
      }
    case ActivityType.ACTIVITY:
      switch (slide.subtype) {
        case ActivitySubtype.WORD_CLOUD:
          return (
            <RenderWordCloudActivity
              slide={slide}
              role={role}
              channel={channel}
              showResults={showResults}
              previewMode={previewMode}
            />
          );
        case ActivitySubtype.PIN_IT:
          return (
            <RenderPinItActivity
              slide={slide}
              channel={channel}
              showResults={showResults}
              previewMode={previewMode}
            />
          );
        case ActivitySubtype.POLL:
          return (
            <RenderPollActivity
              slide={slide}
              role={role}
              channel={channel}
              showResults={showResults}
              previewMode={previewMode}
            />
          );
        case ActivitySubtype.QUIZ:
          return (
            <RenderQuizActivity
              slide={slide}
              role={role}
              channel={channel}
              showResults={showResults}
              previewMode={previewMode}
            />
          );
        case ActivitySubtype.OPEN_QUESTION:
          return (
            <RenderOpenQuestionActivity
              slide={slide}
              role={role}
              channel={channel}
              showResults={showResults}
              onClickNext={onClickNext}
              previewMode={previewMode}
            />
          );
        default:
          return <div>Unknown activity subtype</div>;
      }
    default:
      return <div>Unknown activity type</div>;
  }
};
