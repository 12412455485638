import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface TimePickerProps {
  selectedTime: Date;
  onChange: (time: Date) => void;
}

export const CustomTimePicker: React.FC<TimePickerProps> = ({
  selectedTime,
  onChange,
}) => {
  const CustomInput = forwardRef<
    HTMLInputElement,
    React.HTMLProps<HTMLInputElement>
  >(({ value, onClick }, ref) => (
    <input
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      className='!w-full cursor-pointer rounded-lg border p-2 shadow-inner'
    />
  ));

  return (
    <DatePicker
      selected={selectedTime}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption='Time'
      dateFormat='h:mm aa'
      wrapperClassName='!w-full'
      customInput={<CustomInput />}
    />
  );
};
