// ModalLesson.js
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  handleActivityProjected,
  LessonSelectors,
  setModalLesson,
  UserSelectors,
} from '@store/slices';
import {
  ACTIVITY_PROJECTED_STORAGE_KEY,
  STATES_STR,
  UserRole,
} from '@domain/constants';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { Modal } from '@components/molecules';
import { ModalLessonOptions } from '@components/organisms';
import { Typography } from '@components/atoms';

export const ModalLesson = ({
  refetchLessonsPage,
}: {
  refetchLessonsPage: () => void;
}) => {
  const { t } = useTranslation('components/organisms/modalLesson');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classId, moduleId } = useParams();

  const userRole = useSelector(UserSelectors.getRole);
  const {
    isOpen,
    title,
    description,
    state,
    isProjectingLesson,
    publicUid,
    videoLinkOne,
    videoLinkTwo,
    lessonInProgress,
  } = useSelector(LessonSelectors.getModalLesson);

  const closeModalLesson = () => {
    dispatch(setModalLesson({ isOpen: false }));
  };

  const navigateToVideos = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.videos
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', publicUid)
    );
    closeModalLesson();
    dispatch(handleActivityProjected(isProjectingLesson));
    localStorage.setItem(
      ACTIVITY_PROJECTED_STORAGE_KEY,
      isProjectingLesson.toString()
    );
  };

  const navigateToActivity = () => {
    navigate(
      (userRole === UserRole.CATECHIST
        ? PROTECTED_ROUTES_PATHS.lessonSummary
        : PROTECTED_ROUTES_PATHS.activity
      )
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', publicUid)
    );
    closeModalLesson();
    dispatch(handleActivityProjected(isProjectingLesson));
    localStorage.setItem(
      ACTIVITY_PROJECTED_STORAGE_KEY,
      isProjectingLesson.toString()
    );
  };

  const navigateToPreviewActivity = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.activityPreview
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', publicUid)
    );
  };

  const navigateToReviewActivity = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.activityReview
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', publicUid)
    );
  };

  return (
    <Modal open={isOpen} onClose={closeModalLesson} title={title}>
      <div className='flex flex-col justify-between gap-16'>
        <div className='grid gap-4'>
          <Typography variant='p'>{description}</Typography>
          {state === STATES_STR.skipped && userRole === UserRole.CATECHIST && (
            <Typography variant='span' className='font-bold'>
              {t('lessonSkipped')}
            </Typography>
          )}
        </div>

        <div className='space-y-4'>
          <ModalLessonOptions
            userRole={userRole}
            publicUid={publicUid}
            lessonInProgress={lessonInProgress}
            state={state}
            navigateToVideos={navigateToVideos}
            navigateToActivity={navigateToActivity}
            isProjectingLesson={isProjectingLesson}
            videoLinkOne={videoLinkOne}
            videoLinkTwo={videoLinkTwo}
            refetchLessonsPage={refetchLessonsPage}
            navigateToPreviewActivity={navigateToPreviewActivity}
            navigateToReviewActivity={navigateToReviewActivity}
          />
        </div>
      </div>
    </Modal>
  );
};
