import { useQuery } from '@tanstack/react-query';
import { MdChevronLeft } from 'react-icons/md';
import { ShopContainer, Typography } from '@components/atoms';
import { ShopItemList, ModalAvatarPurchase } from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { useShop } from '@hooks/index';
import { useEffect, useState } from 'react';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { trackGA4PageView } from '@data/utils';
import { PAGE_NAMES } from '@domain/constants';
import { motion } from 'framer-motion';

export const ShopAvatarsPage = () => {
  const [avatar, setAvatar] = useState<Record<string, string | number> | null>(
    null
  );

  const { t } = useTranslation('pages/home/shop/shopAvatarsPage');
  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const { getShopItemsByType, navigateToShop } = useShop();
  const { data, isLoading, isPending, refetch } = useQuery({
    queryKey: ['shopItemsByAvatar'],
    queryFn: () => getShopItemsByType('avatar'),
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shopAvatars,
      title: PAGE_NAMES.shopAvatars,
    });
  }, []);

  return (
    <HomeTemplate
      hideBottomNavigation
      childrenClassname='!bg-secondary-surface-100'
    >
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <motion.div
          initial={ { opacity: 0} }
          animate={{ opacity: 1 }}
          transition={{ 
            delay: 0.3,
            duration: 0.5,
            type: "spring", 
            stiffness: 200, 
            damping: 5 }}
           >
        <MdChevronLeft
          size={24}
          className='cursor-pointer text-secondary-buttons-500'
          onClick={navigateToShop}
        />
        </motion.div>
        <motion.div
          initial={{ y: '-130%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }}       // Slide to the original position
          transition={{
            duration: 0.3,
            ease: "easeInOut"
          }}
          >
        <Typography variant='h1'>{t('title')}</Typography>
        </motion.div>

        <motion.div
          initial={ {opacity: 0} }
          animate={ { opacity: 1} }
          transition={{
            duration: 0.7,
            ease: "easeInOut"
          }}
          >
          <ShopItemList
            items={data}
            studentPoints={studentAvailablePoints}
            onClick={item => setAvatar(item)}
          />
        </motion.div>
        <ModalAvatarPurchase
          open={!!avatar}
          setAvatar={setAvatar}
          handleCancel={() => setAvatar(null)}
          refetch={refetch}
          {...avatar}
        />
      </ShopContainer>
    </HomeTemplate>
  );
};
