import {
  Layout,
  Divider,
  Input,
  Button,
  Typography,
  BottomCard,
} from '@components/atoms';
import { LoginImage } from '@components/molecules';
import { useLogin } from '@hooks/index';
import { useForm } from 'react-hook-form';
import { MdQrCode } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Vitral } from '@assets/svg';
import { InputType } from '@domain/constants';
import { loginRules, trackGA4PageView } from '@data/utils';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useEffect } from 'react';
import { PAGE_NAMES } from '@domain/constants';

type Inputs = {
  login: string;
  password: string;
};

export const LoginPage = () => {
  const { loginUserMutation, navigateToSignup, navigateToForgotPassword } =
    useLogin();

  const { t } = useTranslation(['pages/auth/login/loginPage']);

  const handleLoginSubmit = async (data: Inputs) => {
    await loginUserMutation.mutateAsync({
      login: data.login,
      password: data.password,
    });
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      login: '',
      password: '',
    },
    mode: 'onChange',
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PUBLIC_ROUTES_PATHS.login,
      title: PAGE_NAMES.login,
    });
  }, []);

  return (
    <Layout containerStyles='!bg-primary-400 !p-0'>
      <div className='flex h-dvh flex-col justify-between'>
        <LoginImage image={Vitral} />
        <BottomCard>
          <form onSubmit={handleSubmit(handleLoginSubmit)}>
            <Input
              label={t('inputs.email')}
              type={InputType.text}
              control={control}
              placeholder='Username'
              name='login'
              rules={loginRules(t('errors.email'))}
              inputMode='email'
            />
            <div className='flex flex-col'>
              <Input
                label={t('inputs.password')}
                type={InputType.password}
                control={control}
                placeholder='Password'
                name='password'
                inputMode='text'
                rules={loginRules(t('errors.password'))}
              />
              <Button
                type='button'
                style='link'
                onClick={navigateToForgotPassword}
                className='-mt-8 self-end !text-primary-400'
                text={t('buttons.forgotPassword')}
              ></Button>
            </div>

            <Button
              type='submit'
              style='primary'
              disabled={!isValid || loginUserMutation.isPending}
              loading={loginUserMutation.isPending}
              text={t('buttons.login')}
            />
          </form>
          <div className='mt-4 flex h-full w-full flex-col items-center justify-start gap-4'>
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('createAccount')}
            </Typography>
            <Button
              type='button'
              style='secondary'
              onClick={navigateToSignup}
              className='w-full'
              text={t('buttons.signup')}
            />

            <Divider text={t('or')} className='w-full' />

            <div className='flex w-full items-center justify-center gap-4 px-4'>
              <MdQrCode size={24} className='flex-shrink-0 text-primary-400' />
              <Typography
                variant='span'
                className='w-48 font-bold !text-primary-400'
              >
                {t('scanCode')}
              </Typography>
            </div>
          </div>
        </BottomCard>
      </div>
    </Layout>
  );
};
