import { MdChurch, MdArrowForward } from 'react-icons/md';
import { Typography, ProgressBar, Card } from '@components/atoms';
import { ClassImage } from '@assets/svg';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { removeClassStorage, setClassStorage } from '@data/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimatedProgressBar } from '../../atoms/Animated/AnimatedProgressBar';
import { useState } from 'react';

export interface ClassCardProps {
  publicUid: string;
  name: string;
  parishName: string;
  modules: Modules;
  backgroundImage: string;
}

interface Modules {
  total: number;
  finished: number;
}

export const ClassCard = ({
  publicUid,
  name,
  parishName,
  modules,
  backgroundImage,
}: ClassCardProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const navigateToModules = () => {
    removeClassStorage();
    setClassStorage({ name, publicUid });
    navigate(PROTECTED_ROUTES_PATHS.modules.replace(':classId', publicUid));
  };

  const handleClick = () => {
    setIsAnimating(true);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 1 }}
        animate={
          isAnimating ? { scale: 1.1, opacity: 1 } : { scale: 1, opacity: 1 }
        }
        onClick={handleClick}
        onAnimationComplete={isAnimating ? navigateToModules : undefined} // Only navigate when animation is done
      >
        <Card
          className='relative bg-primary-400 [&_*]:!text-secondary-buttons-600'
          onClick={handleClick}
        >
          <img
            src={backgroundImage || ClassImage}
            alt={`${name} background image`}
            className='absolute bottom-0 right-0 z-0'
          ></img>
          <div className='z-10 flex w-2/3 flex-col gap-2'>
            <Typography
              variant='span'
              className='text-[18px] font-bold leading-[22px]'
            >
              {name}
            </Typography>
            <div className='flex gap-2'>
              <MdChurch className='flex-shrink-0' size={16} />
              <Typography variant='span' className='text-wrap-ellipsis'>
                {parishName}
              </Typography>
            </div>
            <ProgressBar
              value={modules.finished}
              max={modules.total}
              containerStyle='mt-4 progress-filled:bg-secondary-phasethree-400'
            />
          </div>
          <MdArrowForward size={24} className='z-10 flex-shrink-0' />
        </Card>
      </motion.div>
    </AnimatePresence>
  );
};
