import { useCallback, useEffect, useState, useRef } from 'react';
import { usePubNub } from 'pubnub-react';
import {
  ActivityPayload,
  SlideActionType,
  UseActivityProps,
  CustomMessageEvent,
  SlideBodyType,
  ActivitySubtype,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

export const useDueDate = ({ channel, slideId }: UseActivityProps) => {
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [isDateSet, setIsDateSet] = useState(false);
  const pubnub = usePubNub();
  const userId = useSelector(UserSelectors.getId);
  const listenerRef = useRef<any>(null);

  const handleDueDateUpdate = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.DUE_DATE &&
        body.activity_id === slideId
      ) {
        const newDueDate = new Date(body.dueDate);
        if (!isNaN(newDueDate.getTime())) {
          setDueDate(newDueDate);
          setIsDateSet(true);
        }
      }
    },
    [slideId]
  );

  const sendDueDateUpdate = useCallback(async (newDueDate: Date) => {
    const messagePayload: ActivityPayload = {
      uuid: userId,
      action: SlideActionType.ACTIVITY,
      body: {
        type: SlideBodyType.ACTIVITY,
        activity_type: ActivitySubtype.DUE_DATE,
        dueDate: newDueDate.toISOString(),
        activity_id: slideId,
      },
      subscribed_channel: channel,
      actual_channel: channel,
    };

    await pubnub.publish({
      channel: channel,
      message: messagePayload,
    });
    setIsDateSet(true);
  }, [userId, slideId, channel, pubnub]);

  useEffect(() => {
    if (!listenerRef.current) {
      listenerRef.current = {
        message: handleDueDateUpdate,
      };
      pubnub.addListener(listenerRef.current);
    }

    pubnub.subscribe({ channels: [channel] });

    return () => {
      if (listenerRef.current) {
        pubnub.removeListener(listenerRef.current);
        listenerRef.current = null;
      }
      pubnub.unsubscribe({ channels: [channel] });
    };
  }, [pubnub, channel, handleDueDateUpdate]);

  return {
    dueDate,
    isDateSet,
    sendDueDateUpdate,
  };
};
