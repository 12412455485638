import { Navigate } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { UserRole } from '@domain/constants';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'src/data/store/slices';

interface Roles {
  allowedRoles: UserRole[];
  children: ReactNode;
}

export const ProtectedRole = ({ allowedRoles, children }: Roles) => {
  const userRole = useSelector(UserSelectors.getRole);

  if (allowedRoles.includes(userRole)) {
    return children;
  }

  return <Navigate to={PROTECTED_ROUTES_PATHS.home} />;
};
