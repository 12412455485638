import { Card, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { getLessonTextSubtype } from '@data/utils';

interface LessonSummaryProps {
  content: any[];
}

export const LessonSummary = ({ content }: LessonSummaryProps) => {
  const { t } = useTranslation('components/organisms/lessonSummary');

  return (
    <div className='w-full space-y-4'>
      {content.map(slide => {
        const { type, subtype } = slide;
        const text = getLessonTextSubtype(type, subtype, t);

        return (
          <Card
            key={slide.id}
            className='flex bg-secondary-surface-200 px-4 py-4'
          >
            <div className='flex w-full flex-col'>
              <Typography
                variant='span'
                className='line-clamp-1 text-[16px] font-bold'
              >
                {slide.title}
              </Typography>
              <Typography variant='span'>{text}</Typography>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
