import {
  BorderContainer,
  RichTextTypography,
  Typography,
  YoutubeEmbed,
} from '@components/atoms';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { ActivitySubtype, ActivityType, UserRole } from '@domain/constants';
import { useSelector } from 'react-redux';
import { ActivitySelectors, UserSelectors } from '@store/slices';
import { MdInfo } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

interface RenderVideoSlideProps {
  title: string;
  videoURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
  textContent?: string;
  showVideo?: boolean;
}

export const RenderVideoSlide = ({
  title,
  videoURL,
  type,
  subtype,
  textContent,
  showVideo,
}: RenderVideoSlideProps) => {
  const isActivityProjected = useSelector(
    ActivitySelectors.getIsActivityProjected
  );
  const userRole = useSelector(UserSelectors.getRole);
  const { t } = useTranslation('components/templates/renderVideoSlide');

  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <div className='grid h-full flex-1 grid-cols-1 gap-4 desktop:grid-cols-3'>
        {userRole === UserRole.STUDENT && isActivityProjected && !showVideo ? (
          <BorderContainer containerStyles='flex items-center gap-2 desktop:col-span-2 border border-primary-200 p-4 mb-8 '>
            <MdInfo size={24} className='flex-shrink-0 text-primary-400' />
            <Typography variant='span'>{t('videoProjected')}</Typography>
          </BorderContainer>
        ) : (
          <YoutubeEmbed
            url={videoURL}
            className='mb-8 flex-1 desktop:col-span-2'
          />
        )}
        {textContent ? (
          <RichTextTypography description={textContent} />
        ) : (
          <Typography variant='span' className='!text-secondary-text-200'>
            {t('noComments')}
          </Typography>
        )}
      </div>
    </SlideContentWrapper>
  );
};
