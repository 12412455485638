export enum STATES {
  finished = 0,
  inProgress = 1,
  notStarted = 2,
}

export enum STATES_STR {
  finished = 'finished',
  inProgress = 'in_progress',
  notStarted = 'not_started',
  skipped = 'skipped',
}

export enum SHOP_STATES_STR {
  active = 'active',
  archived = 'archived',
  upcoming = 'upcoming',
}

export enum LESSON_STATES_STR {
  start = 'start',
  complete = 'complete',
  stop = 'stop',
  reset = 'reset',
  skip = 'skip',
}

export enum RETO_STATES {
  draft = 0,
  submitted = 1,
  graded = 2,
}

export enum RETO_STATES_STR {
  draft = 'draft',
  submitted = 'submitted',
  graded = 'graded',
}

export enum ACHIEVEMENT_STATES {
  active = 'active',
  archived = 'archived',
}

export enum ANSWER_STATUS {
  correct = 'correct',
  incorrect = 'incorrect',
}
