import {
  Content,
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
  UserRole,
} from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  FavoriteAnswer,
  OpenQuestionResponses,
  SlideTextInput,
} from '@components/molecules';
import { useOpenQuestion } from '@hooks/index';
import { Button } from '@components/atoms';
import { useEffect, useState } from 'react';
import {
  ActivitySelectors,
  handleFavoriteAnswer,
  handleStudentSendButtonState,
  PreviewModeProps,
  resetStudentSendButtonState,
} from '@store/slices';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsesProps } from '@components/molecules/OpenQuestionResponse/OpenQuestionResponse';
import { useTranslation } from 'react-i18next';

interface RenderOpenQuestionActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
  onClickNext: () => void;
  previewMode: PreviewModeProps;
}

interface RenderOpenQuestionActivityContentProps {
  showChoiceAward: boolean;
  answerSubmitted: boolean;
  setAnswerSubmitted: (answerSubmitted: boolean) => void;
  role: UserRole;
  slide: Content;
  sendOpenQuestionResponse: (answer: string) => void;
  responses: Record<string, string>[];
  sendShowChoiceAward: (answer: boolean) => void;
  onClickNext: () => void;
  handleLike: (responseIndex: number) => void;
  sendFavoriteResponses: (responses: Record<string, string>[]) => void;
  favoriteResponses: Record<string, string>[] | undefined;
  isStudentView: boolean;
}

const RenderOpenQuestionContent = ({
  showChoiceAward,
  answerSubmitted,
  setAnswerSubmitted,
  role,
  slide,
  sendOpenQuestionResponse,
  responses,
  handleLike,
  sendShowChoiceAward,
  sendFavoriteResponses,
  favoriteResponses,
  isStudentView,
}: RenderOpenQuestionActivityContentProps) => {
  const dispatch = useDispatch();
  const previewMode = useSelector(ActivitySelectors.getPreviewMode);
  const { t } = useTranslation('components/organisms/slideControls');

  useEffect(() => {
    if (showChoiceAward) {
      dispatch(resetStudentSendButtonState());
      dispatch(handleStudentSendButtonState({ isDisabled: true }));
      dispatch(handleFavoriteAnswer({ isShowingFavoriteAnswer: true }));
    }
  }, [showChoiceAward, dispatch]);

  if (showChoiceAward) {
    return <FavoriteAnswer favoriteResponses={favoriteResponses} />;
  }

  if (
    answerSubmitted ||
    (role === UserRole.CATECHIST && !previewMode.isPreviewMode) ||
    (previewMode.isPreviewMode && !isStudentView)
  ) {
    const getFavoriteResponses = (responses: Record<string, string>[]) => {
      const allZeroLikes = responses.every(
        response => response.likes?.count === 0
      );
      if (allZeroLikes) return [];

      const maxLikes = Math.max(
        ...responses.map(response => response.likes?.count || 0)
      );

      const topFavoriteResponses = responses.filter(
        response => response.likes?.count === maxLikes
      );

      return topFavoriteResponses;
    };

    const handleShowFavoriteAnswer = async () => {
      await sendFavoriteResponses(getFavoriteResponses(responses));
      await sendShowChoiceAward(true);
    };

    return (
      <div>
        <OpenQuestionResponses
          handleLike={handleLike}
          role={role}
          responses={responses}
        />
        {role === UserRole.CATECHIST && !previewMode.isPreviewMode && (
          <div className='fixed bottom-4 left-4 right-4 z-10'>
            <Button
              style='primary'
              text={t('nextActivity')}
              onClick={handleShowFavoriteAnswer}
            />
          </div>
        )}
      </div>
    );
  }

  if (
    (!answerSubmitted && role === UserRole.STUDENT) ||
    (isStudentView && previewMode.isPreviewMode)
  ) {
    const handleSubmitResponse = async (answer: string) => {
      await sendOpenQuestionResponse(answer);
      setAnswerSubmitted(true);
    };

    return (
      <SlideTextInput
        isTextarea
        activitySubtype={slide.subtype}
        onSubmit={handleSubmitResponse}
      />
    );
  }
};

export const RenderOpenQuestionActivity = ({
  slide,
  role,
  channel,
  onClickNext,
  previewMode,
}: RenderOpenQuestionActivityProps) => {
  const [previewResponses, setPreviewResponses] = useState<ResponsesProps[]>([
    {
      id: '1',
      username: 'Bryan Martínez',
      response:
        'The weekend was awesome! We went to the zoo on Saturday and saw all my favorite animals, especially the monkeys! They were swinging everywhere. Then on Sunday, I had a sleepover with Sarah and we built a giant blanket fort in the living room. We stayed up super late telling stories with flashlights!',
      likes: {
        count: 2,
        userIds: [1, 82],
      },
    },
    {
      id: '2',
      username: 'Herbert Paz',
      response:
        'I had a great weekend! On Saturday, I went hiking with my friends at a nearby trail.',
      likes: {
        count: 3,
        userIds: [1, 82, 12],
      },
    },
  ]);
  const [previewShowChoiceAward, setPreviewShowChoiceAward] = useState(false);
  const [previewFavoriteResponses, setPreviewFavoriteResponses] = useState<
    Record<string, string>[]
  >([]);

  // Only use PubNub hook if not in preview mode
  const {
    responses,
    sendOpenQuestionResponse,
    sendShowChoiceAward,
    showChoiceAward,
    handleLike,
    sendFavoriteResponses,
    favoriteResponses,
  } = previewMode.isPreviewMode
    ? {
        responses: previewResponses,
        sendOpenQuestionResponse: async (answer: string) => {
          setPreviewResponses(prev => [
            ...prev,
            {
              answer,
              likes: { count: 0 },
              userId: `preview${prev.length + 1}`,
            },
          ]);
        },
        sendShowChoiceAward: async (show: boolean) => {
          setPreviewShowChoiceAward(show);
        },
        showChoiceAward: previewShowChoiceAward,
        handleLike: (responseIndex: number) => {
          setPreviewResponses(prev =>
            prev.map((response, index) =>
              index === responseIndex
                ? {
                    ...response,
                    likes: { count: (response.likes?.count || 0) + 1 },
                  }
                : response
            )
          );
        },
        sendFavoriteResponses: async (responses: Record<string, string>[]) => {
          setPreviewFavoriteResponses(responses);
        },
        favoriteResponses: previewFavoriteResponses,
      }
    : useOpenQuestion({
        channel,
        slideId: slide.id,
      });

  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  useEffect(() => {
    setAnswerSubmitted(false);
    localStorage.removeItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY);
  }, [slide.id]);

  return (
    <SlideContentWrapper
      title={slide.openQuestion?.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      <RenderOpenQuestionContent
        answerSubmitted={answerSubmitted}
        setAnswerSubmitted={setAnswerSubmitted}
        showChoiceAward={showChoiceAward}
        role={role}
        slide={slide}
        sendOpenQuestionResponse={sendOpenQuestionResponse}
        sendShowChoiceAward={sendShowChoiceAward}
        responses={responses}
        handleLike={handleLike}
        onClickNext={onClickNext}
        sendFavoriteResponses={sendFavoriteResponses}
        favoriteResponses={favoriteResponses}
        isStudentView={previewMode.isStudentView}
      />
    </SlideContentWrapper>
  );
};
