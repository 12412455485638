import { ActivitySubtype, ActivityType } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { RichTextTypography } from '@components/atoms';
import { ModalSheetLinkEmbed } from '@components/organisms';

interface RenderTextSlideProps {
  title: string;
  textContent: string;
  type: ActivityType;
  subtype: ActivitySubtype;
  link?: string;
}

export const RenderTextSlide = ({
  title,
  textContent,
  type,
  subtype,
  link,
}: RenderTextSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      {textContent && <RichTextTypography description={textContent} />}
      <div className='mt-8'>{link && <ModalSheetLinkEmbed link={link} />}</div>
    </SlideContentWrapper>
  );
};
