import { PinActivity } from '@components/molecules';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { Content } from '@domain/constants';
import { usePin } from '@hooks/index';
import { useState } from 'react';
import { PreviewModeProps } from 'src/data/store/slices';

interface Marker {
  x: number;
  y: number;
  id: string;
}

interface RenderPinItActivityProps {
  slide: Content;
  channel: string;
  showResults: boolean;
  previewMode: PreviewModeProps;
}

export const RenderPinItActivity = ({
  slide,
  channel,
  showResults,
  previewMode,
}: RenderPinItActivityProps) => {
  const [previewMarkers, setPreviewMarkers] = useState<Marker[]>([]);

  // Only use PubNub hook if not in preview mode
  const { markers, sendPinMarker } = previewMode.isPreviewMode
    ? {
        markers: previewMarkers,
        sendPinMarker: (marker: Marker) => {
          setPreviewMarkers(prevMarkers => [...prevMarkers, marker]);
        },
      }
    : usePin({
        channel,
        slideId: slide.id,
      });

  const handleSetMarkers = (marker: Marker) => {
    if (previewMode.isPreviewMode) {
      setPreviewMarkers(prevMarkers => [...prevMarkers, marker]);
    } else {
      sendPinMarker(marker);
    }
  };

  return (
    <SlideContentWrapper
      title={slide.pinIt!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      <PinActivity
        image={slide.pinIt!.imageURL}
        markers={markers}
        setMarkers={handleSetMarkers}
        slideId={slide.id}
        showResults={showResults}
      />
    </SlideContentWrapper>
  );
};
