import { Typography, Lottie, Carousel } from '@components/atoms';
import { HeartBeatingLottie } from '@assets/lotties';
import { QuestionHand } from '@assets/img';
import { useTranslation } from 'react-i18next';

interface FavoriteAnswerProps {
  favoriteResponses: Record<string, string>[] | undefined;
}

const FavoriteResponseCard = ({
  username,
  response,
}: {
  username: string;
  response: string;
}) => {
  return (
    <div className='space-y-2 px-2 py-4'>
      <Typography variant='p' className='font-bold'>
        {username}
      </Typography>
      <Typography variant='p'>{response}</Typography>
    </div>
  );
};

export const FavoriteAnswer = ({ favoriteResponses }: FavoriteAnswerProps) => {
  const { t } = useTranslation('components/molecules/favoriteAnswer');

  return (
    <div className='grid h-full place-content-center'>
      {favoriteResponses && favoriteResponses.length > 0 ? (
        <div className='flex w-full flex-col'>
          <Lottie
            animationData={HeartBeatingLottie}
            className='-mt-12 w-44 self-center'
          />
          <Carousel
            data={favoriteResponses}
            CardComponent={FavoriteResponseCard}
            idLabel='favoriteResponse'
            className='rounded-none'
            classNameItem='flex flex-col rounded-xl border border-primary-200 bg-secondary-surface-200 shadow-inner'
          />
        </div>
      ) : (
        <div className='mx-8 flex h-full flex-col items-center gap-2 [&_*]:text-center'>
          <img className='mb-4 w-44' src={QuestionHand}></img>
          <Typography variant='p' className='font-bold'>
            {t('emptyState.title')}
          </Typography>
          <Typography variant='p'>{t('emptyState.description')}</Typography>
        </div>
      )}
    </div>
  );
};
