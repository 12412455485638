import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  UserReducer,
  OnboardingReducer,
  StudentsReducer,
  ModalSheetReducer,
  ModalCloseLessonReducer,
  LessonReducer,
  ModalLessonNavigationReducer,
  ActivityReducer,
} from './slices';

const rootReducer = combineReducers({
  user: UserReducer,
  onboarding: OnboardingReducer,
  students: StudentsReducer,
  modalSheet: ModalSheetReducer,
  modalCloseLesson: ModalCloseLessonReducer,
  lesson: LessonReducer,
  modalLessonNavigation: ModalLessonNavigationReducer,
  activity: ActivityReducer,
});

const store = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore['dispatch'];
