import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LESSON_STATES_STR, STATES_STR, UserRole } from '@domain/constants';
import { AnimatedButton, BorderContainer, Typography } from '@components/atoms';
import { resetModalLesson, setModalLesson } from '@store/slices';
import { MdInfo } from 'react-icons/md';
import { useLessons } from '@hooks/index';

interface StartLessonOptionsProps {
  lessonInProgress?: string;
  publicUid: string;
  videoLinkOne?: string;
  videoLinkTwo?: string;
  navigateToVideos: () => void;
  navigateToActivity: () => void;
  isProjectingLesson: boolean;
}

const StartLessonOptions = ({
  lessonInProgress,
  publicUid,
  videoLinkOne,
  videoLinkTwo,
  navigateToVideos,
  navigateToActivity,
  isProjectingLesson,
}: StartLessonOptionsProps) => {
  const { t } = useTranslation('components/organisms/modalLesson');
  const dispatch = useDispatch();

  return (
    <>
      {lessonInProgress && lessonInProgress !== publicUid ? (
        <BorderContainer containerStyles='flex items-center gap-2 border border-primary-200 p-4 '>
          <MdInfo size={24} className='flex-shrink-0 text-primary-400' />
          <Typography variant='span'>{t('lessonInProgress')}</Typography>
        </BorderContainer>
      ) : (
        (!lessonInProgress || lessonInProgress === publicUid) && (
          <>
            <div className='flex w-full gap-4'>
              {(videoLinkOne || videoLinkTwo) && (
                <AnimatedButton
                  text={t('buttons.practice')}
                  style='secondary'
                  className='flex'
                  loading={false}
                  disabled={false}
                  onClick={navigateToVideos}
                />
              )}
              <AnimatedButton
                text={t('buttons.start')}
                style='primary'
                className='flex grow'
                loading={false}
                disabled={false}
                onClick={navigateToActivity}
              />
            </div>
            <div className='form-control mb-2 mt-4 self-start'>
              <label className='label flex cursor-pointer justify-start gap-2'>
                <input
                  type='checkbox'
                  checked={isProjectingLesson}
                  onChange={() =>
                    dispatch(
                      setModalLesson({
                        isProjectingLesson: !isProjectingLesson,
                      })
                    )
                  }
                  className='checkbox [--chkbg:#6E2AB1] [--chkfg:#FBFBFB]'
                />
                <Typography variant='span'>{t('projectingClass')}</Typography>
              </label>
            </div>
          </>
        )
      )}
    </>
  );
};

interface ModalLessonOptions {
  publicUid: string;
  lessonInProgress?: string;
  userRole: UserRole;
  state: STATES_STR;
  navigateToVideos: () => void;
  navigateToActivity: () => void;
  isProjectingLesson: boolean;
  videoLinkOne?: string;
  videoLinkTwo?: string;
  refetchLessonsPage: () => void;
  navigateToPreviewActivity: () => void;
  navigateToReviewActivity: () => void;
}

export const ModalLessonOptions = ({
  userRole,
  publicUid,
  state,
  navigateToVideos,
  navigateToActivity,
  isProjectingLesson,
  videoLinkOne,
  videoLinkTwo,
  lessonInProgress,
  refetchLessonsPage,
  navigateToPreviewActivity,
  navigateToReviewActivity,
}: ModalLessonOptions) => {
  const { t } = useTranslation('components/organisms/modalLesson');
  const dispatch = useDispatch();

  const { updateLessonStateMutation } = useLessons();

  const skipLesson = async () => {
    await updateLessonStateMutation.mutateAsync({
      lessonId: publicUid,
      state: LESSON_STATES_STR.skip,
    });
    refetchLessonsPage();
    dispatch(resetModalLesson());
  };

  if (userRole === UserRole.STUDENT) {
    if (state === STATES_STR.finished || state === STATES_STR.skipped) {
      return (
        <AnimatedButton
          style='primary'
          text={t('buttons.review')}
          loading={false}
          disabled={false}
          onClick={navigateToReviewActivity}
        />
      );
    }

    return (
      <AnimatedButton
        style='primary'
        text={t('buttons.join')}
        loading={false}
        disabled={state !== STATES_STR.inProgress}
        onClick={navigateToActivity}
      />
    );
  }

  if (userRole === UserRole.CATECHIST) {
    if (state === STATES_STR.skipped) {
      return (
        <>
          <AnimatedButton
            text={t('buttons.preview')}
            style='secondary'
            loading={false}
            disabled={false}
            onClick={navigateToPreviewActivity}
          />
          <StartLessonOptions
            isProjectingLesson={isProjectingLesson}
            lessonInProgress={lessonInProgress}
            publicUid={publicUid}
            navigateToActivity={navigateToActivity}
            navigateToVideos={navigateToVideos}
            videoLinkOne={videoLinkOne}
            videoLinkTwo={videoLinkTwo}
          />
        </>
      );
    }

    if (state === STATES_STR.finished) {
      return (
        <AnimatedButton
          text={t('buttons.review')}
          style='secondary'
          loading={false}
          disabled={false}
          onClick={navigateToReviewActivity}
        />
      );
    }

    return (
      <>
        <AnimatedButton
          text={t('buttons.markAsDone')}
          style='secondary'
          loading={updateLessonStateMutation.isPending}
          disabled={updateLessonStateMutation.isPending}
          onClick={skipLesson}
        />
        <AnimatedButton
          text={t('buttons.preview')}
          style='secondary'
          loading={false}
          disabled={false}
          onClick={navigateToPreviewActivity}
        />
        <StartLessonOptions
          isProjectingLesson={isProjectingLesson}
          lessonInProgress={lessonInProgress}
          publicUid={publicUid}
          navigateToActivity={navigateToActivity}
          navigateToVideos={navigateToVideos}
          videoLinkOne={videoLinkOne}
          videoLinkTwo={videoLinkTwo}
        />
      </>
    );
  }

  return null;
};
