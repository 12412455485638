import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { LessonTemplate, Slide, SlideContent } from '@components/templates';
import { useLessons } from '@hooks/index';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handlePreviewMode } from '@store/slices';

export const PreviewContentPage = () => {
  const { contentId } = useParams();
  const { getShowContent } = useLessons();
  const dispatch = useDispatch();

  const { data, isFetching, isLoading, isError } = useQuery({
    queryKey: ['showContent', contentId],
    queryFn: () => getShowContent(contentId!),
    enabled: !!contentId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isError) {
    throw new Error();
  }

  useLayoutEffect(() => {
    dispatch(handlePreviewMode({ isPreviewMode: true }));
  }, [dispatch]);

  const isDataLoading = isLoading || isFetching;

  return (
    <>
      {data && !isDataLoading && (
        <LessonTemplate
          title={data.lesson.title}
          subtitle={data.lesson.description}
          headerStyles={'bg-secondary-phasethree-400'}
          isLoading={isDataLoading}
          childrenClassName='!mx-0 !pt-0 bg-secondary-surface-100'
        >
          <Slide
            currentStep={1}
            maxSteps={3}
            type={data.content.type}
            subtype={data.content.subtype}
            nextButtonText={'Next'}
            onClickNext={() => {}}
            onShowResults={undefined}
            showResults={undefined}
          >
            <SlideContent
              slide={data.content}
              channel={'si'}
              showResults={false}
              onClickNext={() => {}}
            />
          </Slide>
        </LessonTemplate>
      )}
    </>
  );
};
