import { StudentCard } from '@components/molecules';
import { MdWorkspacePremium } from 'react-icons/md';
import { motion } from 'framer-motion';

interface StudentCardListProps {
  students: Record<string, string | number>[];
  containerStyles?: string;
  showBadges?: boolean;
  showScores?: boolean;
  onClick?: (id: number) => void;
  nameStyle?: string;
}

const badgeClasses = [
  'text-secondary-phasethree-400',
  'text-secondary-phasetwo-400',
  'text-secondary-phasefour-400',
];

export const StudentCardList = ({
  students,
  containerStyles,
  showBadges,
  showScores,
  nameStyle,
  onClick,
}: StudentCardListProps) => {
  const showBadgeByPosition = (position: number) => {
    if (!showBadges) return;

    if (position >= 0 && position <= 2) {
      return (
        <MdWorkspacePremium
          size={24}
          className={`flex-shrink-0 ${badgeClasses[position]}`}
        />
      );
    }
  };

  if (!students || students.length === 0) return <div></div>;

  return (
    <div className={`mb-12 flex flex-1 flex-col gap-6 ${containerStyles}`}>
      {students.map((student, index) => (
        <motion.div
          key={student.id}
          initial={{ x: '-130%', opacity: 0 }} // Start off-screen to the left
          animate={{ x: 0, opacity: 1 }} // Slide to the original positi
          transition={{
            type: 'spring',
            damping: 40,
            stiffness: 500,
            delay: 0.2 + index * 0.1,
          }}
        >
          <StudentCard
            avatar={student.profilePictureURL}
            id={Number(student.id)}
            key={student.id}
            name={`${student.firstName} ${student.lastName}`}
            nameStyle={nameStyle}
            showBadge={showBadges}
            badge={showBadgeByPosition(index)}
            onClick={onClick}
            score={showScores ? student?.score : null}
          />
        </motion.div>
      ))}
    </div>
  );
};
