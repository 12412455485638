import { BorderContainer, Lottie, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { TacoTruckSent } from '@assets/lotties';
import { motion } from 'framer-motion';

interface SlideAnswerSubmittedProps {
  className?: string;
}

export const SlideAnswerSubmitted = ({
  className,
}: SlideAnswerSubmittedProps) => {
  const { t } = useTranslation('components/molecules/slideAnswerSubmitted');

  return (
    <motion.div
      initial={{ x: '-100vw', scale: 0.8, rotate: -5 }} // Start off-screen with a smaller scale and slight rotation
      animate={{ x: 0, scale: 1, rotate: 0 }} // Move to the center of the screen, scale up, and straighten out
      className='col-span-3'
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 10,
        delay: 0.3,
        duration: 0.8,
        ease: 'easeOut',
      }}
    >
      <BorderContainer
        containerStyles={`bg-secondary-surface-100 border border-primary-200 flex flex-col items-center gap-2 ${className}`}
      >
        <Lottie
          animationData={TacoTruckSent}
          style={{
            margin: 'auto',
            width: 200,
            height: 200,
            transform: 'translateY(-50px) translateX(-20px)',
          }}
        />
        <div style={{ transform: 'translateY(-50px)' }}>
          <Typography
            variant='span'
            className='text-[16px] font-bold leading-[20px] !text-primary-400'
          >
            {t('title')}
          </Typography>
          <div className='flex flex-col items-center'>
            <Typography variant='p' className='!text-primary-400'>
              {t('subtitle')}
            </Typography>
          </div>
        </div>
      </BorderContainer>
    </motion.div>
  );
};
