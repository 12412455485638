import { useQuery } from '@tanstack/react-query';
import { MdChevronLeft, MdShoppingBag } from 'react-icons/md';
import { ShopContainer, Typography } from '@components/atoms';
import { ShopItemList } from '@components/organisms';
import { HomeTemplate, ModalSheetCollectible } from '@components/templates';
import { useShop } from '@hooks/index';
import { useEffect, useState } from 'react';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { trackGA4PageView } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';

const EmptyState = ({ emptyStateText }: { emptyStateText: string }) => {
  return (
    <div className='flex w-full flex-col items-center  px-8 text-center'>
      <MdShoppingBag size={40} className='flex-shrink-0 text-primary-300' />
      <Typography variant='span'>{emptyStateText!}</Typography>
    </div>
  );
};

export const PurchasedCollectiblesPage = () => {
  const [collectible, setCollectible] = useState<Record<
    string,
    string | number
  > | null>(null);

  const { t } = useTranslation('pages/home/shop/shopPurchasedCollectiblesPage');
  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const { getShopPurchasedItemsByType, navigateToShopPurchased } = useShop();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['purchasedShopItemsByCollectible'],
    queryFn: () => getShopPurchasedItemsByType('collectible'),
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shopPurchasedCollectibles,
      title: PAGE_NAMES.shopPurchasedCollectibles,
    });
  }, []);

  return (
    <HomeTemplate
      hideBottomNavigation
      childrenClassname='!bg-secondary-surface-100'
    >
      <ShopContainer
        childrenClassname='space-y-4 flex flex-col flex-1'
        isLoading={isLoading || isPending}
      >
        <MdChevronLeft
          size={24}
          className='cursor-pointer text-secondary-buttons-500'
          onClick={navigateToShopPurchased}
        />
        <Typography variant='h1'>{t('title')}</Typography>
        {data && data.length === 0 ? (
          <div className='flex flex-1 flex-col items-center justify-center'>
            <EmptyState emptyStateText="Looks like you haven't bought any colletibles with your taco points yet." />
          </div>
        ) : (
          <>
            <ShopItemList
              items={data}
              studentPoints={studentAvailablePoints}
              onClick={item => setCollectible(item)}
              hidePrice
              hideEmptyState
            />
            <ModalSheetCollectible
              isOpen={!!collectible}
              onClose={() => setCollectible(null)}
              isPurchased={true}
              hidePrice
              {...collectible}
            />
          </>
        )}
      </ShopContainer>
    </HomeTemplate>
  );
};
