import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

interface DailyPrayerGameSubmission {
  points: number;
  all_correct_answers: boolean;
  answer: string;
}

class PrayerGameApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  submitDailyPrayerGame(id: string, submission: DailyPrayerGameSubmission) {
    return this.client.post(
      `/daily_prayer_games/${id}/submit`, 
      {
        daily_prayer_game: {
          points: submission.points,
          all_correct_answers: submission.all_correct_answers,
          answer: submission.answer,
        }
      },
      {
        headers: {
          Accept: '*/*',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }
}

export { PrayerGameApi };
