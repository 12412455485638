import { ComponentType } from 'react';

interface CarouselProps<T extends object> {
  className?: string;
  classNameItem?: string;
  CardComponent: ComponentType<T>;
  data: T[];
  idLabel?: string;
  getCardProps?: (item: T, index: number) => any;
}

export const Carousel = <T extends object>({
  className,
  classNameItem,
  data,
  CardComponent,
  idLabel = 'carouselItem',
  getCardProps,
}: CarouselProps<T>) => {
  return (
    <div>
      <div
        className={`carousel w-full items-center gap-4 rounded-box ${className}`}
      >
        {data.map((item, index) => (
          <div
            className={`carousel-item flex w-[--widthAmount] flex-col ${classNameItem}`}
            style={{ '--widthAmount': data.length === 1 ? '100%' : '92%' }}
            key={index}
            id={`${idLabel}${index}`}
          >
            <CardComponent {...item} {...getCardProps?.(item, index)} />
          </div>
        ))}
      </div>
      {data.length > 1 && (
        <div className='mt-4 flex w-full justify-center gap-2'>
          {data.map((_, index) => (
            <a key={index} href={`#${idLabel}${index}`} className='btn btn-xs'>
              {index + 1}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
