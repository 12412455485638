import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { SlideTextInput, Wordcloud } from '@components/molecules';
import { useWordCloud } from '@hooks/index';
import { useState } from 'react';
import { PreviewModeProps } from '@store/slices';

interface Word {
  text: string;
  value: number;
}

interface RenderWordCloudActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
  previewMode: PreviewModeProps;
}

export const RenderWordCloudActivity = ({
  slide,
  role,
  channel,
  showResults,
  previewMode,
}: RenderWordCloudActivityProps) => {
  const [previewWords, setPreviewWords] = useState<Word[]>([
    // Initial preview data
    { text: 'Example', value: 5 },
    { text: 'Preview', value: 4 },
    { text: 'Word', value: 3 },
    { text: 'Cloud', value: 3 },
  ]);

  const { words, sendWordCloudResponse } = previewMode?.isPreviewMode
    ? {
        words: previewWords,
        sendWordCloudResponse: (word: string) => {
          setPreviewWords(prevWords => {
            const existingWord = prevWords.find(
              w => w.text.toLowerCase() === word.toLowerCase()
            );

            if (existingWord) {
              return prevWords.map(w =>
                w.text.toLowerCase() === word.toLowerCase()
                  ? { ...w, value: w.value + 1 }
                  : w
              );
            } else {
              return [...prevWords, { text: word, value: 1 }];
            }
          });
        },
      }
    : useWordCloud({
        channel,
        slideId: slide.id,
      });

  const screenWidth = window.screen.width - 48;
  const screenHeight = window.screen.height / 3;

  return (
    <SlideContentWrapper
      title={slide.wordCloud!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {(role === UserRole.CATECHIST && !previewMode.isPreviewMode) ||
      (previewMode.isPreviewMode && !previewMode.isStudentView) ? (
        <Wordcloud words={words} width={screenWidth} height={screenHeight} />
      ) : !showResults || previewMode.isStudentView ? (
        <SlideTextInput
          onSubmit={sendWordCloudResponse}
          activitySubtype={slide.subtype}
        />
      ) : (
        <Wordcloud words={words} width={screenWidth} height={screenHeight} />
      )}
    </SlideContentWrapper>
  );
};
