import { BottomCard } from '@components/atoms';
import { SlideSubtitle, SlideSubtitleProps } from '@components/molecules';
import {
  ProgressStepper,
  ProgressStepperProps,
  SlideControls,
  type SlideControlsProps,
} from '@components/organisms';
import { ReactNode } from 'react';

interface SlideProps
  extends SlideControlsProps,
    SlideSubtitleProps,
    Omit<ProgressStepperProps, 'leftComponent'> {
  children: ReactNode;
}

export const Slide = ({
  nextButtonText,
  children,
  onClickNext,
  subtype,
  type,
  currentStep,
  maxSteps,
  onShowResults,
  showResults,
}: SlideProps) => {
  return (
    <div className='flex h-dvh flex-col !bg-secondary-surface-100 pt-[100px]'>
      <ProgressStepper
        stepperStyles='!text-secondary-text-300'
        containerStyles='px-6 py-8'
        currentStep={currentStep}
        maxSteps={maxSteps}
        leftComponent={<SlideSubtitle type={type} subtype={subtype} />}
      />
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner justify-between !p-6 '>
        <div className='flex flex-1 flex-col justify-between'>
          <div className='mb-20 h-full overflow-auto'>{children}</div>
          <SlideControls
            nextButtonText={nextButtonText}
            onClickNext={onClickNext}
            onShowResults={onShowResults}
            showResults={showResults}
            type={type}
            subtype={subtype}
          />
        </div>
      </BottomCard>
    </div>
  );
};
