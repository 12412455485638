import { ReactNode } from 'react';
import { Awning } from '@assets/svg';
import { Loader } from '@components/atoms';

interface ShopContainerProps {
  children: ReactNode;
  childrenClassname?: string;
  isLoading?: boolean;
}

export const ShopContainer = ({
  children,
  childrenClassname,
  isLoading,
}: ShopContainerProps) => {
  return (
    <div className='flex flex-1 flex-col'>
      <div className='bg-awning absolute left-0 right-0 top-[90px] h-[90px] bg-contain bg-repeat-x'></div>

      {isLoading ? (
        <div className='flex flex-1 items-center justify-center'>
          <Loader />
        </div>
      ) : (
        <section className={`mt-16 ${childrenClassname}`}>{children}</section>
      )}
    </div>
  );
};
