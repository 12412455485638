import { Input, Textarea } from '@components/atoms';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';
import {
  everythingPattern,
  oneWordPattern,
  trackGA4Events,
  validateAppropiateLanguage,
} from '@data/utils';
import {
  ActivityAnalytics,
  activityDoneLabel,
  ActivitySubtype,
  InputType,
  STUDENT_LATEST_RESPONSE_STORAGE_KEY,
} from '@domain/constants';
import {
  ActivitySelectors,
  handleStudentSendButtonState,
  resetStudentSendButtonState,
  UserSelectors,
} from '@store/slices';
import { useDispatch, useSelector } from 'react-redux';

interface SlideTextInputProps {
  questionText?: string;
  onSubmit: (answer: string) => void;
  activitySubtype?: ActivitySubtype;
  isTextarea?: boolean;
}

type Inputs = {
  response: string;
};

export const SlideTextInput = ({
  onSubmit,
  activitySubtype,
  isTextarea,
}: SlideTextInputProps) => {
  const { t } = useTranslation('components/molecules/slideTextInput');
  const dispatch = useDispatch();
  const studentSendButtonState = useSelector(
    ActivitySelectors.getStudentSendButtonState
  );

  const userId = useSelector(UserSelectors.getId);

  const handleSubmitResponse = async (data: Inputs) => {
    try {
      dispatch(handleStudentSendButtonState({ isLoading: true }));
      await onSubmit(data.response);

      localStorage.setItem(STUDENT_LATEST_RESPONSE_STORAGE_KEY, data.response);

      trackGA4Events(
        ActivityAnalytics.categories.activityDone,
        ActivityAnalytics.actions.activity,
        activityDoneLabel(
          new Date().toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          userId.toString(),
          activitySubtype?.toString() || ''
        )
      );
      dispatch(
        handleStudentSendButtonState({
          isLoading: false,
          isDisabled: true,
          isAnswered: true,
        })
      );

      reset();
    } catch (error) {
      console.log(error);
      dispatch(resetStudentSendButtonState());
      throw error;
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      response: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(handleStudentSendButtonState({ isDisabled: !isValid }));
  }, [isValid, dispatch]);

  return (
    <div>
      <form id='slide-form' onSubmit={handleSubmit(handleSubmitResponse)}>
        {isTextarea ? (
          <Textarea
            control={control}
            label=''
            name='response'
            disabled={
              studentSendButtonState.isAnswered ||
              studentSendButtonState.isHidden
            }
            className={
              'h-52 max-h-52 min-h-12 resize-none overflow-auto leading-[18px]'
            }
            rules={{
              ...validateAppropiateLanguage(),
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            placeholder='Type your answer here'
          />
        ) : (
          <Input
            control={control}
            label=''
            name='response'
            type={InputType.text}
            disabled={
              studentSendButtonState.isAnswered ||
              studentSendButtonState.isHidden
            }
            rules={{
              ...validateAppropiateLanguage(),
              required: {
                value: true,
                message: t('errors.required'),
              },
              pattern: {
                value:
                  activitySubtype === ActivitySubtype.WORD_CLOUD
                    ? oneWordPattern
                    : everythingPattern,
                message: t('errors.oneWord'),
              },
            }}
          />
        )}
      </form>

      {studentSendButtonState.isAnswered && <SlideAnswerSubmitted />}
    </div>
  );
};
