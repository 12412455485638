import { useEffect, useState } from 'react';
import { LessonsPath } from '@components/organisms';
import { LessonTemplate } from '@components/templates';
import {
  ACTIVITY_PROJECTED_STORAGE_KEY,
  PAGE_NAMES,
  STATES_STR,
  UserRole,
} from '@domain/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import {
  handleActivityProjected,
  resetLesson,
  resetNavigationState,
  resetStudentSendButtonState,
  setModalLesson,
  UserSelectors,
} from '@store/slices';
import { getLessonStorage, trackGA4PageView } from '@data/utils';
import { useLessons } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ModalLesson } from '@components/templates';
import { AnimatedButton } from '@components/atoms';
import { useTranslation } from 'react-i18next';

export const LessonsPage = () => {
  const [isSomeLessonInProgress, setIsSomeLessonInProgress] = useState('');
  const { classId, moduleId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getLessons } = useLessons();
  const location = useLocation();
  const { title, description, color, backgroundColor } = getLessonStorage();
  const userRole = useSelector(UserSelectors.getRole);
  const { data, isLoading, isPending, refetch } = useQuery({
    queryKey: ['getLessons'],
    queryFn: getLessons,
    networkMode: 'always',
    gcTime: 0,
    retry: false,
  });
  const { t } = useTranslation(
    'pages/home/home/classes/modules/lessons/lessonsPage'
  );

  const handleBackButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.modules
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  };

  useEffect(() => {
    const lessonInProgress = data?.lessons.find(
      lesson => lesson.state === STATES_STR.inProgress
    );

    if (lessonInProgress) {
      setIsSomeLessonInProgress(lessonInProgress.publicUid);
      dispatch(
        setModalLesson({
          lessonInProgress: lessonInProgress.publicUid,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(resetLesson());
    dispatch(handleActivityProjected(false));
    dispatch(resetNavigationState());
    dispatch(resetStudentSendButtonState());
    localStorage.setItem(ACTIVITY_PROJECTED_STORAGE_KEY, 'false');
  }, [dispatch]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.lessons,
    });
  }, [location.pathname]);

  const navigateToActivity = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.activity
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', isSomeLessonInProgress)
    );
    dispatch(setModalLesson({ isOpen: false }));
  };

  return (
    <LessonTemplate
      title={title}
      subtitle={description}
      style={{ backgroundColor, color }}
      isLoading={isLoading || isPending}
      clickBackButton={handleBackButton}
      layoutContainerStyles='!bg-secondary-surface-100'
    >
      <LessonsPath lessons={data?.lessons} />
      {isSomeLessonInProgress && userRole === UserRole.STUDENT && (
        <div className='absolute bottom-6 left-4 right-4 z-10'>
          <AnimatedButton
            text={t('buttons.join')}
            style='primary'
            onClick={navigateToActivity}
          />
        </div>
      )}
      <ModalLesson refetchLessonsPage={refetch} />
    </LessonTemplate>
  );
};
