export const lessonsAdapter = (response: any) => {
  const transformedLessons = response.lessons.map(lesson => ({
    publicUid: lesson.public_uid,
    title: lesson.lesson_name,
    description: lesson.description,
    state: lesson.state,
    videoLinkOne: lesson.video_link1,
    videoLinkTwo: lesson.video_link2,
  }));

  return {
    lessons: transformedLessons,
  };
};
