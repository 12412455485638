import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { STATES_STR } from '@domain/constants';

interface LessonSlice {
  isWaitingRoom: boolean;
  modalLesson: ModalLessonProps;
}

interface ModalLessonProps {
  isOpen: boolean;
  title: string;
  description: string;
  publicUid: string;
  isProjectingLesson: boolean;
  state: STATES_STR;
  videoLinkOne?: string;
  videoLinkTwo?: string;
  lessonInProgress: string;
}

const initialState: LessonSlice = {
  isWaitingRoom: true,
  modalLesson: {
    isOpen: false,
    title: '',
    description: '',
    publicUid: '',
    state: STATES_STR.notStarted,
    isProjectingLesson: false,
    videoLinkOne: '',
    videoLinkTwo: '',
    lessonInProgress: '',
  },
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setWaitingRoom: (
      state,
      action: PayloadAction<LessonSlice['isWaitingRoom']>
    ) => {
      state.isWaitingRoom = action.payload;
    },

    setModalLesson: (
      state,
      action: PayloadAction<Partial<ModalLessonProps>>
    ) => {
      state.modalLesson = { ...state.modalLesson, ...action.payload };
    },

    resetModalLesson: state => {
      state.modalLesson = initialState.modalLesson;
    },
    resetLesson: state => {
      state.isWaitingRoom = false;
    },
  },
});

export const LessonSelectors = {
  getIsWaitingRoom: (state: RootState) => state.lesson.isWaitingRoom,
  getModalLesson: (state: RootState) => state.lesson.modalLesson,
};

export const { setWaitingRoom, resetLesson, setModalLesson, resetModalLesson } =
  lessonSlice.actions;

export const LessonReducer = lessonSlice.reducer;
