import { useTranslation } from 'react-i18next';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import { HttpStatusCode } from '@domain/api';
import {
  AchievementsAnalytics,
  badgeObtainedLabel,
  ToastNotificationType,
} from '@domain/constants';
import { achievedBadgesAdapter, achievementsAdapter } from '@data/adapters';
import { useDispatch, useSelector } from 'react-redux';
import { openInfoModal, UserSelectors } from '@store/slices';

export const useAchievements = () => {
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const userId = useSelector(UserSelectors.getId);

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const getAchievements = async () => {
    try {
      const response = await api.achievements.getAchievements();

      if (response.status === HttpStatusCode.OK) {
        return achievementsAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getAchievedBadges = async () => {
    try {
      const response = await api.users.getAchievedBadges();

      if (response.status === HttpStatusCode.OK) {
        const adaptedResponse = achievedBadgesAdapter(response.data);

        if (adaptedResponse.lessonCompletion) {
          const {
            id: badgeId,
            title,
            description,
            imageURL,
            level,
            levels,
          } = adaptedResponse.lessonCompletion;
          dispatch(
            openInfoModal({
              title: `Level ${levels - level.level + 1}`,
              description: description,
              image: imageURL,
              isBadge: true,
              backgroundColor: 'blue',
              level: level.level,
              subtitle: title,
              levels: levels,
            })
          );

          trackGA4Events(
            AchievementsAnalytics.categories.badgeObtained,
            AchievementsAnalytics.actions.achievements,
            badgeObtainedLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              badgeId.toString()
            )
          );
        }

        if (adaptedResponse.pointLandmarks) {
          const {
            id: badgeId,
            title,
            description,
            imageURL,
            level,
            levels,
          } = adaptedResponse.pointLandmarks;
          dispatch(
            openInfoModal({
              title: `Level ${levels - level.level + 1}`,
              description: description,
              image: imageURL,
              isBadge: true,
              backgroundColor: 'blue',
              level: level.level,
              subtitle: title,
              levels: levels,
            })
          );
          trackGA4Events(
            AchievementsAnalytics.categories.badgeObtained,
            AchievementsAnalytics.actions.achievements,
            badgeObtainedLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              badgeId.toString()
            )
          );
        }

        if (adaptedResponse.dailyPrayerStreak) {
          const {
            id: badgeId,
            title,
            description,
            imageURL,
            level,
            levels,
          } = adaptedResponse.dailyPrayerStreak;
          dispatch(
            openInfoModal({
              title: `Level ${levels - level.level + 1}`,
              description: description,
              image: imageURL,
              isBadge: true,
              backgroundColor: 'blue',
              level: level.level,
              subtitle: title,
              levels: levels,
            })
          );

          trackGA4Events(
            AchievementsAnalytics.categories.badgeObtained,
            AchievementsAnalytics.actions.achievements,
            badgeObtainedLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              badgeId.toString()
            )
          );
        }

        if (adaptedResponse.correctQuizCompleted) {
          const {
            id: badgeId,
            title,
            description,
            imageURL,
            level,
            levels,
          } = adaptedResponse.correctQuizCompleted;
          dispatch(
            openInfoModal({
              title: `Level ${levels - level.level + 1}`,
              description: description,
              image: imageURL,
              isBadge: true,
              backgroundColor: 'blue',
              level: level.level,
              subtitle: title,
              levels: levels,
            })
          );
          trackGA4Events(
            AchievementsAnalytics.categories.badgeObtained,
            AchievementsAnalytics.actions.achievements,
            badgeObtainedLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              badgeId.toString()
            )
          );
        }

        return adaptedResponse;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  return {
    getAchievements,
    getAchievedBadges,
  };
};
