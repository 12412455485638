import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getLessonStorage } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { BottomCard, Button, YoutubeEmbed } from '@components/atoms';
import { TabSelector } from '@components/organisms';
import { LessonTemplate } from '@components/templates';
import { useLessons } from '@hooks/index';
import { useEffect, useState } from 'react';
import { LessonVideosIds } from '@domain/constants';

export const LessonVideosPage = () => {
  const { title, description, color, backgroundColor } = getLessonStorage();
  const { moduleId, classId, lessonId } = useParams();
  const { getLessons } = useLessons();

  const [tabs, setTabs] = useState<Array<{ id: string; label: string }>>([
    {
      id: LessonVideosIds.alpha,
      label: 'Alpha',
    },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  };

  const handleContinueButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessonSummary
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', lessonId!)
    );
  };

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['lessonDetails'],
    queryFn: getLessons,
  });

  const isQueryLoading = isLoading || isPending;

  const lesson = data?.lessons.find(lesson => lesson.publicUid === lessonId);
  const videos = [lesson?.videoLinkOne, lesson?.videoLinkTwo].filter(
    video => video != null
  );

  useEffect(() => {
    if (!isPending && videos.length === 2) {
      setTabs([
        {
          id: LessonVideosIds.alpha,
          label: 'Alpha',
        },
        {
          id: LessonVideosIds.omega,
          label: 'Omega',
        },
      ]);
    }
  }, [videos.length, isPending, tabs.length]);

  return (
    <LessonTemplate
      title={title}
      subtitle={description}
      style={{ backgroundColor, color }}
      isLoading={isQueryLoading}
      clickBackButton={handleBackButton}
      childrenClassName={`!mx-0 mt-6 bg-secondary-surface-100`}
    >
      <BottomCard containerStyles='bg-secondary-surface-200 flex-1 shadow-inner !p-6 overflow-hidden h-full'>
        <TabSelector
          tabs={tabs}
          useStateNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className='mt-8'>
          <YoutubeEmbed
            url={videos[activeTab === LessonVideosIds.alpha ? 0 : 1]}
            className='!h-72'
          />
        </div>
        <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
          <Button
            style='primary'
            text='Continue'
            className='!w-[90%]'
            onClick={handleContinueButton}
          />
        </div>
      </BottomCard>
    </LessonTemplate>
  );
};
