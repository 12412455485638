import {
  emailPattern,
  namePattern,
  phoneNumberPattern,
  passwordPattern,
} from '@data/utils';
import { PASSWORD_MIN_LENGTH } from '@domain/constants';
import {
  DataSet,
  RegExpMatcher,
  englishDataset,
  englishRecommendedTransformers,
  pattern,
} from 'obscenity';

const myDataset = new DataSet<{ originalWord: string }>()
  .addAll(englishDataset)
  .addPhrase(phrase =>
    phrase.setMetadata({ originalWord: 'linker' }).addPattern(pattern`linker`)
  );

const matcher = new RegExpMatcher({
  ...myDataset.build(),
  ...englishRecommendedTransformers,
});

const APPROPIATE_LANGUAGE_MESSAGE = 'Please use appropriate language.';

export const validateAppropiateLanguage = () => {
  return {
    validate: (value: string) => {
      if (matcher.hasMatch(value)) {
        return APPROPIATE_LANGUAGE_MESSAGE;
      }
      return true;
    },
  };
};

export const retoRules = () => {
  return {
    ...validateAppropiateLanguage(),
    required: true,
  };
};

export const loginRules = (requiredMessage: string) => {
  return {
    ...validateAppropiateLanguage(),
    required: {
      value: true,
      message: requiredMessage,
    },
  };
};

export const usernameRules = () => {
  return {
    ...validateAppropiateLanguage(),
    required: {
      value: false,
    },
  };
};

export const nameRules = (requiredMessage: string, patternMessage: string) => {
  return {
    ...validateAppropiateLanguage(),
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: namePattern,
      message: patternMessage,
    },
  };
};

export const studentEmailRules = (patternMessage: string) => {
  return {
    ...validateAppropiateLanguage(),
    pattern: {
      value: emailPattern,
      message: patternMessage,
    },
  };
};

export const emailRules = (requiredMessage: string, patternMessage: string) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: emailPattern,
      message: patternMessage,
    },
  };
};

export const passwordRules = (
  requiredMessage: string,
  minLengthMessage: string,
  patternMessage: string
) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: passwordPattern,
      message: patternMessage,
    },
    minLength: {
      value: PASSWORD_MIN_LENGTH,
      message: minLengthMessage,
    },
  };
};

export const phoneNumberRules = (
  requiredMessage: string,
  patternMessage: string
) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: phoneNumberPattern,
      message: patternMessage,
    },
  };
};
