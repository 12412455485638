import React from 'react';
import { Typography, BorderContainer } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '@data/utils';

interface DueDateProps {
  date: Date | null;
}

export const DueDate: React.FC<DueDateProps> = ({ date }) => {
  const { t } = useTranslation('components/molecules/dueDate');
  const formattedDate = date ? formatDateTime(date) : t('noDateSet');

  return (
    <BorderContainer
      containerStyles='p-4 bg-secondary-surface-100 w-full min-h-12 flex-shrink-0'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitAppearance: 'none',
        WebkitTextSizeAdjust: '100%',
      }}
    >
      <Typography
        variant='span'
        className='text-base font-medium leading-5 !text-primary-400'
        style={{
          WebkitTextSizeAdjust: '100%',
        }}
      >
        {`${t('submitBy')} ${formattedDate}`}
      </Typography>
    </BorderContainer>
  );
};
