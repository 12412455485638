export enum SlideActionType {
  ACTIVITY = 'activity',
  CHANGE_SLIDE = 'change_slide',
  FINISH_LESSON = 'finish_lesson',
  EXIT_LESSON = 'exit_lesson',
  SHOW_RESULTS = 'show_results',
  SHOW_CLASS_CHOICE = 'show_class_choice',
  UPDATE_LIKE = 'update_like',
  FAVORITE_RESPONSES = 'favorite_responses',
}

export enum SlideBodyType {
  ACTIVITY = 'activity',
  SLIDE = 'slide',
}
