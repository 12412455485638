import { MdBook } from 'react-icons/md';
import { Typography, Card } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

export interface ReminderCardProps {
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
}

export const ReminderCard = ({
  icon,
  title,
  subtitle,
  onClick,
}: ReminderCardProps) => {
  const { t } = useTranslation('components/molecules/reminderCard');

  return (
    <Card className='carousel-item !p-0 !shadow-lgInner' onClick={onClick}>
      <div className='flex w-full items-center !justify-start gap-4 p-4'>
        <div className='rounded-full p-2 shadow-smInner'>
          {icon ? (
            icon
          ) : (
            <MdBook size={24} className='flex-shrink-0 !text-primary-400' />
          )}
        </div>
        <div className='flex w-full flex-col'>
          <Typography
            variant='span'
            className='text-[16px] font-bold leading-[22px]'
          >
            {title ?? t('nextWeek')}
          </Typography>
          {subtitle && (
            <Typography
              variant='span'
              className='line-clamp-3 text-[16px] leading-[22px]'
            >
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
    </Card>
  );
};
