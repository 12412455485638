import { useDispatch, useSelector } from 'react-redux';
import {
  ActivitySelectors,
  ModalLessonNavigationSelectors,
  handleModalLessonNavigation,
  handleNavigationState,
} from '@store/slices';
import { ACTIVITY_PROJECTED_STORAGE_KEY } from 'src/domain/constants';
import { ModalSheet, NavigationCard } from '@components/molecules';

interface ModalSheetLessonNavigationProps {
  content: Record<string, string | number>[];
  onClick: (index: number, isPassedSlide: boolean) => void;
  currentStep: number;
}

export const ModalSheetLessonNavigation = ({
  content,
  onClick,
  currentStep,
}: ModalSheetLessonNavigationProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(ModalLessonNavigationSelectors.getIsOpen);
  const { maximumStepReached } = useSelector(
    ActivitySelectors.getNavigationState
  );

  const handleClose = () => {
    dispatch(handleModalLessonNavigation(false));
  };

  // Check if the activity is being projected on another screen
  const activityProjectedSelector = useSelector(
    ActivitySelectors.getIsActivityProjected
  );
  const activityProjectedStorage =
    localStorage.getItem(ACTIVITY_PROJECTED_STORAGE_KEY) === 'true';
  const isActivityProjected =
    activityProjectedSelector || activityProjectedStorage;

  const handleClickCard = (index: number, isActivityProjected: boolean) => {
    if (currentStep === index) {
      dispatch(handleModalLessonNavigation(false));
      return;
    } else if (index <= maximumStepReached) {
      dispatch(handleNavigationState({ hasNavigatedBack: true }));
      onClick(index, isActivityProjected);
      dispatch(handleModalLessonNavigation(false));
    }
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={handleClose}
      initialSnap={0}
      snapPoints={[0.7]}
    >
      <div className='mb-8 flex flex-col gap-4 overflow-scroll'>
        {content &&
          content.length > 0 &&
          content.map((slide, index) => {
            const isPassedSlide = maximumStepReached >= index;

            return (
              <NavigationCard
                key={slide.id}
                isPassedSlide={isPassedSlide}
                slide={slide}
                onClick={() => handleClickCard(index, isActivityProjected)}
              />
            );
          })}
      </div>
    </ModalSheet>
  );
};
