export const ActivityAnalytics = {
  // ACTIONS
  actions: {
    activity: 'Activity',
  },

  // CATEGORIES
  categories: {
    activityDone: 'Activity Done',
  },

  // LABELS
  labels: {
    activityDone: ':date; :userID; :activityID',
  },
};

export const activityDoneLabel = (
  date: string,
  userId: string,
  activityId: string
) => {
  return ActivityAnalytics.labels.activityDone
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':activityID', activityId);
};
