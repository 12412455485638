import { HomeTemplate } from '@components/templates';
import { ClassesList, ReminderList, GuidesList } from '@components/organisms';
import { CardActiveLesson, GameCard } from '@components/molecules';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { GUIDES_LINKS, PAGE_NAMES, UserRole } from '@domain/constants';
import { useHome } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { setDailyPrayerGameStorage, trackGA4PageView } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { MdChurch } from 'react-icons/md';

export const HomePage = () => {
  const userRole = useSelector(UserSelectors.getRole);
  const { getHome } = useHome();
  const { t } = useTranslation('pages/home/home/homePage');

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getHome'],
    queryFn: getHome,
    retry: false,
  });

  useEffect(() => {
    if (data?.dailyPrayerGame) {
      setDailyPrayerGameStorage(data.dailyPrayerGame);
    }
  }, [data]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.home,
      title: PAGE_NAMES.home,
    });
  }, []);

  const guides = [
    {
      title: t('guides.mass.title'),
      icon: <MdChurch size={24} className='flex-shrink-0 text-primary-400' />,
      subtitle: t('guides.mass.subtitle'),
      link: GUIDES_LINKS.mass,
    },
    {
      title: t('guides.prayer.title'),
      icon: <MdChurch size={24} className='flex-shrink-0 text-primary-400' />,
      subtitle: t('guides.prayer.subtitle'),
      link: GUIDES_LINKS.prayer,
    },
  ];

  return (
    <HomeTemplate isLoading={isLoading || isPending}>
      <GameCard />
      <ClassesList classes={data?.courses} />
      {data?.lesson && userRole === UserRole.STUDENT && (
        <div className='mt-4'>
          <CardActiveLesson {...data.lesson} />
        </div>
      )}
      {userRole === UserRole.STUDENT && (
        <>
          <ReminderList
            reminder={data?.retos?.length > 0 ? data?.retos[0] : null}
          />
          <GuidesList guides={guides} />
        </>
      )}
    </HomeTemplate>
  );
};
