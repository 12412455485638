export interface ContentResponses {
  content: Content;
}

export interface Content {
  id: number;
  content_type: string;
  additional_type: string;
  created_at: Date;
  updated_at: Date;
  responses: Response[];
}

export interface Response {
  student_id: number;
  points: number;
  pubnub_time: Date;
  body: Body;
}

export interface Body {
  answer?: string;
  x_position?: number;
  y_position?: number;
}

export const lessonDetailsAdapter = response => {
  const { lesson } = response;

  const adaptedContent = lesson.content.map(item => {
    const adaptedItem = {
      id: item.id,
      title: item.title,
      description: item.description,
      type: item.content_type,
      subtype: item.additional_type,
      pointsAwarded: item.points_awarded,
      position: item.position,
    };

    if (item.text_content) {
      adaptedItem.textContent = item.text_content;
      adaptedItem.link = item.link;
    }

    if (item.word_cloud) {
      adaptedItem.wordCloud = item.word_cloud;
    }

    if (item.video_url) {
      adaptedItem.videoURL = item.video_url;
      adaptedItem.textContent = item.text_content;
    }

    if (item.image_url) {
      adaptedItem.imageURL = item.image_url;
      adaptedItem.textContent = item.text_content;
    }

    if (item.poll) {
      adaptedItem.poll = item.poll;
    }

    if (item.open_question) {
      adaptedItem.openQuestion = item.open_question;
    }

    if (item.pin_it) {
      adaptedItem.pinIt = {
        question: item.pin_it.question,
        pinX: item.pin_it.pin_x,
        pinY: item.pin_it.pin_y,
        imageURL: item.pin_it.image_url,
      };
    }

    if (item.quiz) {
      adaptedItem.quiz = {
        question: item.quiz.question,
        options: item.quiz.options,
        correctOption: item.quiz.correct_option,
      };
    }

    return adaptedItem;
  });

  const adaptedRetos = lesson.retos.map(reto => ({
    id: reto.id,
    title: reto.title,
    description: reto.description,
    type: reto.challenge_type,
    dueDate: reto.due_date,
    pointsAwarded: reto.points_awarded,
  }));

  const adaptedEndingPrayerGame = lesson.ending_prayer_game
    ? {
        id: lesson.ending_prayer_game.id,
        prayerTitle: lesson.ending_prayer_game.prayer_title,
        prayerText: lesson.ending_prayer_game.prayer,
        maxBlanks: lesson.ending_prayer_game.max_blanks,
        blankPositions: lesson.ending_prayer_game.blank_positions,
        correctAnswers: lesson.ending_prayer_game.correct_answers,
        pointsAwarded: lesson.ending_prayer_game.points_awarded,
        words: lesson.ending_prayer_game.correct_answers || [],
      }
    : null;

  return {
    lesson: {
      publicUid: lesson.public_uid,
      title: lesson.title,
      description: lesson.description,
      activeSlide: lesson.active_slide,
      state: lesson.state,
      content: adaptedContent,
      retos: adaptedRetos,
      endingPrayerGame: adaptedEndingPrayerGame,
    },
  };
};

export const lessonPointsAdapter = (response: any) => {
  const { achievement } = response;
  const adaptedPoints = {
    moduleFinished: achievement.module_finished,
    courseFinished: achievement.course_finished,
    currentLessonPointsExceeded: achievement.current_lesson_points_exceeded,
    maxLessonPointsExceeded: achievement.max_lesson_points_exceeded,
    availablePoints: achievement.available_points,
    lifetimePoints: achievement.lifetime_points,
    lessonScore: achievement.lesson_score,
  };

  return adaptedPoints;
};

export const leaderboardAdapter = (response: any) => {
  const { students } = response;
  if (!students || students.length === 0) return [];

  const adaptedLeaderboard = students.map(student => {
    return {
      id: student.id,
      firstName: student.first_name,
      lastName: student.last_name,
      score: student.score,
      profilePictureURL: student.profile_picture_url,
    };
  });

  return adaptedLeaderboard;
};

export const showContentAdapter = (response: any) => {
  const { content, module, lesson } = response;

  const adaptedContent = {
    id: content.id,
    title: content.title,
    description: content.description,
    type: content.content_type,
    subtype: content.additional_type,
    pointsAwarded: content.points_awarded,
    position: content.position,
  };

  if (content.text_content) {
    adaptedContent.textContent = content.text_content;
    adaptedContent.link = content.link;
  }

  if (content.word_cloud) {
    adaptedContent.wordCloud = content.word_cloud;
  }

  if (content.video_url) {
    adaptedContent.videoURL = content.video_url;
    adaptedContent.textContent = content.text_content;
  }

  if (content.image_url) {
    adaptedContent.imageURL = content.image_url;
    adaptedContent.textContent = content.text_content;
  }

  if (content.poll) {
    adaptedContent.poll = content.poll;
  }

  if (content.open_question) {
    adaptedContent.openQuestion = content.open_question;
  }

  if (content.pin_it) {
    adaptedContent.pinIt = {
      question: content.pin_it.question,
      pinX: content.pin_it.pin_x,
      pinY: content.pin_it.pin_y,
      imageURL: content.pin_it.image_url,
    };
  }
  if (content.quiz) {
    adaptedContent.quiz = {
      question: content.quiz.question,
      options: content.quiz.options,
      correctOption: content.quiz.correct_option,
    };
  }

  return {
    content: adaptedContent,
    module,
    lesson,
  };
};

export const getContentResponsesAdapter = (response: ContentResponses) => {
  const adaptedResponses = response.content.responses.map(response => ({
    studentId: response.student_id,
    points: response.points,
    pubnubTime: response.pubnub_time,
    body: response.body.answer
      ? response.body.answer
      : {
          x_position: response.body.x_position,
          y_position: response.body.y_position,
        },
  }));

  return adaptedResponses;
};
